import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
  selector: 'app-program3',
  templateUrl: './program3.component.html',
  styleUrls: ['./program3.component.scss']
})
export class Program3Component implements OnInit {

    programDetails = details[2];

    program_id = this.programDetails.id;
    program_name = this.programDetails.name;
    program_url = this.programDetails.url;

    constructor() {}

    ngOnInit(): void {}
}
