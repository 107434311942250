<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team DBIMA</li>
            </ul>
            <h2>Team DBIMA</h2>

        </div>
        <img src="assets/img/site/illustrations/teaching.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="terms-of-service-area ">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let professor of professorsDetails; let i = index">
                        <div class="single-advisor-box" style="margin-top: 30px;">
                            <div class="row align-items-center cs-hide-small cs-hide-medium">
                                <div class="col-lg-4 col-md-4" *ngIf="((i+1)%2) == 1">
                                    <div class="advisor-image">
                                        <img src="{{baseImgUrl + professor.img_name}}" onerror="onErrChangeSrc(this)" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="advisor-content" align='{{align[((i+1)%2)]}}'>
                                        <h3>{{professor.name}}</h3>
                                        <span class="sub-title">{{professor.designation}}</span>
                                        <p>{{professor.description}}</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4" *ngIf="((i+1)%2) == 0">
                                    <div class="advisor-image">
                                        <img src="{{baseImgUrl + professor.img_name}}" onerror="onErrChangeSrc(this)" alt="image">
                                    </div>
                                </div>
                            </div>

                            <div class="row align-items-center cs-hide-large ">
                                <div class="col-lg-4 col-md-4">
                                    <div class="advisor-image">
                                        <img src="{{baseImgUrl + professor.img_name}}" onerror="onErrChangeSrc(this)" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="advisor-content">
                                        <h3>{{professor.name}}</h3>
                                        <span class="sub-title">{{professor.designation}}</span>
                                        <p>{{professor.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cs-row pb-100">
                    <div class="cs-col" align="center">
                        <p>Added workshops and specialized courses are held by visiting professors</p>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>