<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>News & Events</li>
            </ul>
            <h2>News & Events</h2>

        </div>
        <img src="assets/img/site/illustrations/events.svg" alt="" style="height: 180px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="events-area pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">

                <div class="mobileMenu odemy-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Type:</label>
                            <select class="mobileSelect" onchange="sortNAE(this)">
                                <option class="filter" value="all">All</option>
                                <option class="filter" value="news">News</option>
                                <option class="filter" value="ongoing">Ongoing Events</option>
                                <option class="filter" value="upcoming">Upcoming Events</option>
                                <option class="filter" value="past">Past Events</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="shorting-menu shorting-menu-style-two cs-transparent">
                    <button class="filter" onclick="sortNAE(this)" id="sortToAllNewsEvents_btn" value="ALL">All</button>
                    <button class="filter" value="news" onclick="sortNAE(this)">News</button>
                    <button class="filter" value="ongoing" onclick="sortNAE(this)">Ongoing Events</button>
                    <button class="filter" value="upcoming" onclick="sortNAE(this)">Upcoming Events</button>
                    <button class="filter" value="past" onclick="sortNAE(this)">Past Events</button>
                </div>

                <div class="row cs-hide cs-animate-zoom" id="nodata_div">
                    <div class="col-12" align="center">
                        <div class="ptb-70">
                            <img src="assets/img/site/illustrations/not_found.svg" alt=""
                                style="height: 180px;width: auto;">
                            <h6 class="mt-5">No Data Found.</h6>
                        </div>
                    </div>
                </div>

                <div class="shorting">
                    <div class="row">
                        <div *ngFor="let event of eventList;"
                            class="col-lg-4 col-md-6 col-sm-6 px-1 neContain neCat-{{event.status}}">
                            <div
                                class="single-events-box cs-border cs-border-light-gray cs-round-medium cs-animate-zoom">
                                <div class="image">
                                    <a routerLink="/news-and-events-details/{{event.id}}/{{event.url}}" class="d-block">
                                        <img *ngIf="event.imageUrl != '' && event.videoUrl == '' "
                                            src="https://admin.dbima.eu/app/assets/img/uploads/ps-news-events/{{event.imageUrl}}"
                                            alt="image" onerror="onErrChangeSrc(this)"
                                            style="width: 750px;height: 230px;object-fit: cover;" />

                                        <img *ngIf="event.imageUrl == '' && event.videoUrl != '' "
                                            src="https://img.youtube.com/vi/{{event.videoId}}/0.jpg" alt="image"
                                            onerror="onErrChangeSrc(this)"
                                            style="width: 750px;height: 230px;object-fit: cover;" />
                                    </a>
                                    <span *ngIf="event.type == 'event'"
                                        class="date cu-bg-clr-3 cs-text-white cs-border">{{fixDate(event.dateTime)}}</span>
                                    <span *ngIf="event.type == 'news'"
                                        class="date cu-bg-clr-3 cs-text-white cs-border">{{fixDate(event.createdAt)}}</span>
                                </div>
                                <div class="content">
                                    <h3>
                                        <a
                                            routerLink="/news-and-events-details/{{event.id}}/{{event.url}}">{{event.title}}</a>
                                    </h3>
                                    <p class="cs-tiny text-uppercase">
                                        <span *ngIf="event.type == 'event' ">{{event.status}}
                                            {{event.type}}</span>
                                        <span *ngIf="event.type == 'news' ">{{event.type}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>