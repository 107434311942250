<section class="courses-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Programs</h2>
            <p style="font-size: 20px;">Embrace dynamic creative economy growth through film and media excellence.</p>
        </div>

        <div class="row">

            <div class="col-lg-6 col-md-12" *ngFor="let program of programDetails">
                <div class="single-courses-item cs-hover-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/site/programs/p{{program.id}}/s.jpg" alt="{{program.s_alt}}">
                                <a routerLink="/{{program.url}}" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <h3><a routerLink="/{{program.url}}">{{program.name}}</a>
                                </h3>
                                <p class="courses-content-footer d-flex justify-content-between align-items-center">
                                    {{lengthFix(program.mini_description,75)}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/programs" class="default-btn">
                        <i class="flaticon-user"></i>View All Programs<span></span>
                    </a>
                    <a routerLink="/download-resources" class="default-btn ms-lg-2" style="background-color: #b3833c;padding-left:30px;">
                        Download Resources <label class="bx bx-cloud-download"
                            style="font-size: 16px;"></label><span></span>
                    </a>
                    <p><a routerLink="/faq">FAQ's</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape16"><img src="assets/img/site/shapes/shape15.png" alt="White Doted line Shape"></div>
</section>
