<div class="boxes-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-box-item cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>LEARN</h3>
                    <p>Develop your media skills to create projects that inspire creativity and foster human values.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-box-item cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-people"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>COLLABORATE</h3>
                    <p>Cultivate enduring meaningful bonds with people from varied cultures while learning. </p>
                    <br/>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-box-item cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>DISCOVER</h3>
                    <p>Explore the cultural richness of Paris and Europe through a 'Study Travel' to broaden perspectives.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-box-item cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-certification"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>CERTIFICATION</h3>
                    <p>Our Diplomas are certificated by EABHES with international recognition of ECTS credits. </p>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
