
export const applicantEmailCheck = 'check-email-in-application';

export const getHomeBanner = "sliders/get-all";
export const getAllProfessors = 'professors/get-all';
export const getAllGallery = 'gallery/get-all';
export const getAllGalleryCatagories = 'gallery/get-all-categories';
export const getAllFlyers = 'flyers/get-all';
export const getAllPartners = 'partners/get-all';
export const getPartnerDetails = 'partners/get-partner-data-';
export const getAllNewsAndEvents = 'news/get-all';
export const getNewsAndEventsDetails = 'news/get-news-data-';
