<section class="widget widget_recent_courses">
    <h3 class="widget-title">Our Programs</h3>

    <article class="item" *ngFor="let program of programDetails">
        <a routerLink="/{{program.url}}" class="thumb"><span class="fullimage cover"
                style="background-image: url(assets/img/site/programs/p{{program.id}}/p.jpg);" role="img"></span></a>
        <div class="info">
            <h4 class="title usmall"><a routerLink="/{{program.url}}">{{program.name}}</a></h4>
        </div>
        <div class="clear"></div>
    </article>
</section>