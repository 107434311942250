<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Our Programs</li>
            </ul>
            <h2>Our Programs</h2>

        </div>
        <img src="assets/img/site/illustrations/random_thoughts.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="courses-area">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We have <span class="count">4</span> programs available for you</p>
            </div>

            <div class="col-lg-6 col-md-6 ordering">
                <input class="form-control cs-animate-input" style="width: 40%;float: right;background-color: white;" id="search_programs" type="text" placeholder="Search" onkeyup="sort_programs('search_programs','program-mini')">
            </div>
        </div>

        <div class="row">

            <div class="col-lg-4 col-md-6 program-mini">
                <div class="single-courses-box">
                    <app-program1-mini></app-program1-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 program-mini">
                <div class="single-courses-box">
                    <app-program2-mini></app-program2-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 program-mini">
                <div class="single-courses-box">
                    <app-program3-mini></app-program3-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 program-mini">
                <div class="single-courses-box">
                    <app-program4-mini></app-program4-mini>
                </div>
            </div>

            <div class="col-lg-12 col-md-6" id="nothing_found_div" style="height: 400px;display: none;">
                <div class="container ">
                    <div class="page-title-content mb-5" align="center">
                        <img src="assets/img/site/illustrations/empty.svg" alt="" style="height: 300px;width: auto;" class="pt-10" alt="Nothing Found">
                        <h2 style="margin-top: 30px;">
                            <p>Nothing Found For <q id="searched_for" class="ml-2"></q></p>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
