<app-home-banner></app-home-banner>

<app-home-boxes></app-home-boxes>

<app-home-programs></app-home-programs>

<app-home-video></app-home-video>

<app-home-eabhes></app-home-eabhes>

<app-home-partners></app-home-partners>
