import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {


    url_data: any;
    faqDiv: any = [false, false];

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        var lang_code = this.url_data.lang_code;
        if (!lang_code) {
            lang_code = 'en';
        }
        const langCodes = ["en", "fr"];

        for (let index = 0; index < langCodes.length; index++) {
            if (langCodes[index] == lang_code) {
                this.faqDiv[index] = true;
            } else {
                this.faqDiv[index] = false;
            }
        }
    }

}
