<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="boxes-area">

                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-12 mb-2" align="center">
                            <h1 class="cs-text-white">DBIMA Academic Portal</h1>
                            <hr>
                        </div>

                        <div class="col-lg-3 col-sm-6 col-md-6">
                            <div style="height: 550px;padding-top: 40px;" class="single-box-item cu-box-shadow cs-hover-pale-yellow cu-pointer" onclick="openInNew('https://admin.dbima.eu')">
                                <img src="assets/img/site/logo/logo_white_border.png" alt="image" style="height: 150px;width:auto;">
                                <br><br>
                                <h3>DBIMA Dashboard</h3>
                                <hr>
                                <div style="text-align: left;">
                                    <div>
                                        <h6>Professors</h6>
                                        <ul class="cs-small">
                                            <li>Book Equipment</li>
                                            <li>Book Classroom</li>
                                            <li>View Classroom Schedule</li>
                                        </ul>

                                        <h6>Students</h6>
                                        <ul class="cs-small">
                                            <li>Book Equipment</li>
                                            <li>View Classroom Schedule</li>
                                            <li>Course Fee Receipts</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-md-6">
                            <div style="height: 550px;padding-top: 40px;" class="single-box-item cu-box-shadow cs-hover-pale-yellow cu-pointer" onclick="openInNew('https://dbi-ecademy.eu/login/index.php')">
                                <img src="assets/img/site/academic_portal/cagliero_lms.png" alt="image" style="height: 150px;width:auto;">
                                <br><br>
                                <h3>Cagliero LMS</h3>
                                <hr>
                                <div style="text-align: left;">
                                    <div>
                                        <h6>Professors</h6>
                                        <ul class="cs-small">
                                            <li>Access LMS</li>
                                            <li>Setup Assignments and Quiz</li>
                                            <li>Add Grading</li>
                                            <li>Mark Atng s
                                                tendance </li>
                                            <li>Schedule Online Class</li>
                                        </ul>

                                        <h6>Students</h6>
                                        <ul class="cs-small">
                                            <li>Access LMS</li>
                                            <li>View Grading</li>
                                            <li>View Attendance</li>
                                            <li>Attend Online Class </li>
                                            <li>Access Digital Resource</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-md-6">
                            <div style="height: 550px;padding-top: 40px;" class="single-box-item cu-box-shadow cs-hover-pale-yellow cu-pointer" onclick="openInNew('https://classroom.google.com/u/0/h')">
                                <img src="assets/img/site/academic_portal/google_classroom.png" alt="image" style="height: 150px;width:auto;">
                                <br><br>
                                <h3>Google Classroom</h3>
                                <hr>
                                <div style="text-align: left;">
                                    <div>
                                        <h6>Professors</h6>
                                        <ul class="cs-small">
                                            <li>Access Academy Email</li>
                                            <li>Access Cloud Drive</li>
                                        </ul>

                                        <h6>Students</h6>
                                        <ul class="cs-small">
                                            <li>Access Academy Email</li>
                                            <li>Access Cloud Drive</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coming-soon-content" style="background-color: transparent;">
                <div class="row">
                    <div class="col-12" style="height: 200px;">
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>