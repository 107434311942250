<section class="partner-area" style="padding-top: 20px;padding-bottom: 30px;">
    <div class="section-title" style="margin-bottom: 30px;">
        <h2>Our Partners</h2>
    </div>
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/Erasmus +.png" alt="image">
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/CO-ER.png" alt="image">
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/SIHE.png" alt="image">
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/part1.png" alt="image">
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/part2.png" alt="image">
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="cu-single-partner-item">
                        <a class="d-block">
                            <img src="assets/img/site/partners/CIDB.png" alt="image">
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
            <div class="row mt-5">
                <div class="col-lg-12 col-md-12">
                    <div style="text-align: center;">
                        <a routerLink="/partners" class="default-btn"><i class=" flaticon-right"></i>View In Details<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
