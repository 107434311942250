import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { getAllPartners } from 'src/app/models/endpoints';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

    partnerList: any;
    partnerListCount = 0;
    base_logo_URl = 'https://admin.dbima.eu/app/assets/img/uploads/ps-partners/logo/';

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.http.get<any>(environment.baseUrl + getAllPartners).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    var dataList = data.data;

                    if (dataList.length != 0) {
                        this.noData = false;

                        for (let index = 0; index < dataList.length; index++) {
                            var navUrl = this.fixurl(dataList[index].name);
                            dataList[index].navUrl = navUrl;
                        }
                    }

                    this.partnerList = dataList;
                    this.partnerListCount = this.partnerList.length;
                    this.loading = false;

                } else {
                    this.loading = false;
                }
            },
            error: (error) => {
                this.openSnackBar('Oops, Something went wrong. please try again later. ' , 1);
                console.log(error.message);
            },
        });
    }

    fixurl(txt) {
        txt = txt.toLowerCase();
        txt = txt.replace(/ /g, "-");
        return txt;
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

}
