<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>

        </div>
        <img src="assets/img/site/illustrations/team_spirit.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="profile-area  pb-70">
    <div class="container">
        <h3 class="sub-title" style="margin-bottom: 50px;color: #00184F;">Message from the CEO</h3>
        <br>
        <div class="profile-box">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="image">
                        <img src="assets/img/site/about_us/CEO.jpg" alt="A picture of our Company CEO">
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="content">
                        <div>
                            <p><b>Dear Students, </b></p>
                            <p>A heartfelt welcome to each of you as you commence your enriching journey at Don Bosco International Media Academy (DBIMA). 
                                As the CEO of this innovative institution, 
                                I am thrilled to address you with great joy and enthusiasm.</p>
                            <p>At DBIMA, we blend traditional education with a project-based approach, fostering creativity and empowering you to realize your dreams. 
                                Our methodology offers hands-on experience through real-world projects, ensuring a seamless transition to the professional realm.</p>
                            <p>Our educational philosophy emphasizes a mix of classroom learning and practical application,
                                allowing you to apply knowledge and skills to diverse projects, and providing a glimpse into your future profession.</p>
                            <p>We are dedicated to fostering a community valuing projects with social, humanitarian, ecological, and spiritual significance.
                                Your active participation in these projects, aligned with your passions, ensures happiness and deep involvement in your creative education.</p>
                            <p>DBIMA transcends the conventional school definition; here, you are not just a student but also a vital part of a team and professional community,
                                creating a holistic and enriching learning experience.</p>
                            <p>Embrace the opportunities and growth ahead in your DBIMA journey. Let us build a community thriving on creativity, innovation, 
                                and commitment to excellence.</p>
                        </div>
                        <br>
                        <p><strong> Welcome to Don Bosco International Media Academy!</strong></p>
                        <br/>
                        <div>
                            <div>Warm regards,</div>
                            <img src="assets/img/site/about_us/CEO_sign.png" alt="" style="height: 100px;width: auto;user-select: none;-webkit-user-drag: none;">
                            <h3>John Paul SWAMINATHAN SDB</h3>
                            <span class="sub-title">CEO, Don Bosco International Media Academy (DBIMA)</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="about-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <p class="cu-clr-5">
                        <b>Don Bosco International Media Academy (DBIMA)</b> is an institution of Higher Education, registered and declared by <b>the Rectorat de Paris and the Académie de Paris.</b> (DBIMA est un établissement privé d’enseignement supérieur
                        hors contrat immatriculé et déclaré auprès du Rectorat de Paris et de l’Académie de Paris. DBIMA souhaite accueillir des étudiants de tous milieux et rendre accessible les études de Cinéma et d'Audiovisuel).
                    </p> -->
                    <p class="cu-clr-5">
                        <b>Training for the Film Industry</b><br> The Bachelor of Arts in Cinema & Audiovisual and the Master of Arts in Cinema & Audiovisual are the two degrees intended for an international education. Courses are taught in English and
                        four specializations are offered: 1. Directing, 2. Image, 3. Production, and 4. Post-Production. These two degrees are certified and recognized as European bachelor’s degree and European master’s degree.

                    </p>
                    <p class="cu-clr-5">
                        <b>Work-Study Training</b><br> This international media education was enriched in 2021 with a BTS in Audiovisual, Editing, and Post-production options as apprenticeship. Unlike the first two courses, this BTS is free of charge
                        for the apprentice who has found a contract with a company. This French diploma is recognized by the state and imposes an intense rhythm on the students and prepares them for the technical work in the industry.
                    </p>
                    <p class="cu-clr-5">
                        <b>Quality Teaching</b><br> For all the degrees offered at DBIMA, the imparting of knowledge is ensured by working professionals selected for their approach and teaching experience. To ensure quality teaching, DBIMA has acquired
                        important resources in the centre of Paris with a teaching staff of more than 35 professors with the state-of-the-art equipments.
                        <br><br> Our faculty reflects our choice of diversity as our teachers are coming from France, USA, Egypt, Spain, Sri Lanka, Iran, Russia, India, Venezuela ...

                    </p>
                    <p class="cu-clr-5">
                        <b>Don Bosco Network</b><br> DBIMA is a member school of the Don Bosco educational network. 
                        We welcome students from diverse backgrounds and aim to make cinema and audiovisual education accessible to all. 
                        Our faculty, representing various countries, ensures a diverse and enriching learning experience.
                    </p>
                    <p class="cu-clr-5">
                        <b>Students in Special Situations</b><br> Those students with difficult and chaotic personal backgrounds are accompanied in their educational journey and DBIMA allows them to build a professional path to receive a complete cultural
                        education and thus leap from their personal history through the films they make during their studies at DBIMA. Faithful to Don Bosco, the pedagogical team works on blossoming of these talents through exchange programs and thus
                        creating the best students who in turn will create a cared environment for the other students who are in need.

                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInLeft">
                                <img src="assets/img/site/about_us/250x220-1.jpg" alt="A girl is shooting a film using a video camera which is mounted to a gimbal">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInDown">
                                <img src="assets/img/site/about_us/280x235-1.jpg" alt="A close up picture of a Video Camera">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInUp">
                                <img src="assets/img/site/about_us/280x270-1.jpg" alt="A picture of a sound engineer">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInRight">
                                <img src="assets/img/site/about_us/270x240-1.jpg" alt="A focused picture of the microphone with a background of a sound engineer setup">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="shape1" data-speed="0.06" data-revert="true"><img src="assets/img/shape1.png" alt="the bottom half of a pink circle Icon"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="assets/img/shape2.png" alt="Angled two black lines and one pink line"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="assets/img/site/shapes/lens.png" alt="pink lense icon" style="height: 400px;width: 400px;"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="assets/img/shape4.png" alt="two pink rings icon">
    </div>
</section>

<section class="funfacts-and-feedback-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="video-box">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a data-lg-size="1280-720" data-src="https://www.youtube.com/watch?v=fXoVjqbZWE8    " data-sub-html="<p>Watch Video</p>" class="d-block">
                            <div class="image">
                                <img src="assets/img/site/about_us/about-us.jpg" class="shadow" alt="image">
                            </div>
                            <div class="video-btn popup-youtube">
                                <i class="flaticon-play"></i>
                            </div>
                        </a>
                    </lightgallery>
                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape2" data-speed="0.06" data-revert="true"><img src="assets/img/shape2.png" alt="Angled two black lines and one pink line"></div>
    <div class="shape3 cs-hide" data-speed="0.06" data-revert="true"><img src="assets/img/site/shapes/lens.png" alt="image" style="height: 300px;width: 300px;"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="assets/img/shape4.png" alt="two pink rings icon">
    </div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</section>

<section class="features-area pb-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3 class="cu-clr-main">Learn</h3>
                    <p style="height: 120px;">Develop your media skills to create projects that inspire creativity and foster human values. </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-people"></i>
                    </div>
                    <h3 class="cu-clr-main">Collaborate</h3>
                    <p style="height: 120px;">Cultivate enduring meaningful bonds with people from varied cultures while learning. </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3 class="cu-clr-main">Discover</h3>
                    <p style="height: 120px;">Explore the cultural richness of Paris and Europe through a 'Study Travel' to broaden perspectives.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box cu-box-shadow">
                    <div class="icon">
                        <i class="flaticon-certification"></i>
                    </div>
                    <h3 class="cu-clr-main">Certification</h3>
                    <p style="height: 120px;">Our Diplomas are certificated by EABHES with international recognition of ECTS credits.</p>
                </div>
            </div>
        </div>
    </div>
</section>
