<div class="get-instant-courses-area-two bg-white">
    <div class="container cs-padding-16">
        <div class="row align-items-center cs-padding">
            <div class="col-lg-4 col-md-12">
                <div class="get-instant-courses-image-style-two cs-padding">
                    <img src="assets/img/site/home/eabhes.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="get-instant-courses-content-style-two">
                    <h2>EABHES Accreditation</h2>
                    <p>
                        Our Bachelor and Master programs are accredited by EABHES which guarantees :
                    </p>
                    <ul class="description-features-list">
                        <li>
                            <b>Enhanced international recruitment and reputation</b> due to their participation in the Bologna Process and compliance with European standards.
                        </li>
                        <li>
                            <b>Competitive graduate employment rates</b> due to the international recognition of your programmes.
                        </li>
                        <li>
                            <b>Quality assurance</b> of academic programmes and school management due to EABHES auditing and supervision services for Accreditation.
                        </li>
                        <li>
                            <b>Stability and sustainable development</b> derived from the solid operational framework that is created based on the holistic and collaborative approach of EABHES Accreditation.
                        </li>
                        <li>
                            <b>Student mobility</b> due to international recognition of ECTS credits.
                        </li>
                        <li>
                            <b>Contribution to pedagogical innovation and academic excellence</b> through EABHES Community, the place to share best practices and facilitate international dialogue in education.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>