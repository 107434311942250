<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Partners</li>
            </ul>
            <h2>Partners</h2>
        </div>
        <img src="assets/img/site/illustrations/agreement.svg" alt="" style="height: 190px; width: auto" class="cs-display-bottomright pt-10 cs-hide-small" />
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>
    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="courses-area pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let partner of partnerList">
                        <div class="single-events-box cs-round-xlarge cs-border">
                            <div class="image">
                                <a routerLink="/partner-details/{{ partner.id }}/{{ partner.navUrl }}" class="d-block cs-padding-large">
                                    <img src="{{ base_logo_URl }}{{ partner.logo_img_name }}" onerror="onErrChangeSrc(this)" alt="image">
                                </a>
                            </div>
                            <div class="content">
                                <b><a routerLink="/partner-details/{{ partner.id }}/{{ partner.navUrl }}">{{
                                        partner.name }}</a></b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>
