import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aims-and-ethos',
  templateUrl: './aims-and-ethos.component.html',
  styleUrls: ['./aims-and-ethos.component.scss']
})
export class AimsAndEthosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
