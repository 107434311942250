<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Vision &amp; Mission</li>
            </ul>
            <h2>Vision &amp; Mission</h2>

        </div>
        <img src="assets/img/site/illustrations/goals.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="terms-of-service-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">

                    <div class="row">
                        <div class="col-lg-6 col-md-12"
                            style="display: grid;place-items: center;padding: 0px;margin: 0px;">
                            <img src="assets/img/site/logo/logo_gold_border.png" alt="DBIMA logo with golden border"
                                style="height: 400px;width: auto;">
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <!-- <h3 class="cu-clr-main"> Aims & Ethos</h3>
                            <p>
                                The media industry is growing quickly worldwide, expected to reach approximately USD 
                                6,709.4 billion by 2030. But education in arts and media is expensive, depriving passionate 
                                and talented youth the chance to study and gain employment in this burgeoning field.
                            </p>
                            <p>
                                The mission of the Don Bosco International Media Academy is to offer every student 
                                regardless of economic means the best education in a technically advanced 
                                and artistically supportive environment. 
                            </p>
                            <p>
                                This mission is at the centre of the broader mission of the Salesian Society of Don Bosco, 
                                recognized by governments worldwide as an international organization distinguished for its 
                                education of underprivileged and youth at risk. 
                            </p> -->
                            <h3 class="cu-clr-main">Vision </h3>
                            <p>
                                Cultivating a holistic media education through premier training and personal mentorship, 
                                fostering youth excellence and humanity's well-being. 
                            </p>
                            <h3 class="cu-clr-main">Mission </h3>
                            <p>
                                Delivering an all-encompassing cinema education through a comprehensive and holistic 
                                formative pedagogy. 
                            </p>
                            <p>
                                Cultivating filmmaking skills that ready students for the future cinema industry via top-tier training and mentorship. 
                            </p>
                            <p>
                                Fostering a commitment to social and environmental justice in young minds to produce media content for humanity's well-being. 
                            </p>
                            <p>
                                Empowering students and faculty to articulate their voices meaningfully and effectively within society.
                            </p>
                        </div>
                    </div>

                    <h3 class="cu-clr-main">Pedagogical Objectives</h3>
                    <ul class="cu-clr-4">
                        <li>To empower students with industry-specific filmmaking skills, nurturing creativity, and knowledge to cultivate good human beings and responsible citizens. </li>
                        <li>To enhance specialized skills such as Film Direction, Cinematography, Production, Postproduction, etc through modern tools, techniques, and entrepreneurial training. </li>
                        <li>To innovate, update, and align the curriculum with international standards, ensuring relevance in local and global contexts of media education. </li>
                        <li>To prioritize experiential learning, emphasizing life skills, holistic development, ethics, collaboration, critical thinking, and a multidisciplinary approach in media education.</li>
                        <li>To create an academic environment conducive to excellence, discovery, creativity, inventiveness, leadership, and lifelong learning for all students under our care.</li>
                    </ul>

                    <!-- <h3 class="cu-clr-main">Sociological Objectives</h3>
                    <ul>
                        <li>Keep tuition fees affordable and inclusive to those who would not otherwise have the means
                            to study in this field.</li>
                        <li>Provide tuition-free education to selected talented candidates from around the world. If
                            you require assistance with tuition please contact us oneworld@dbima.eu</li>
                        <li>Foster the development of a new generation of global media professionals. Artists and
                            craftspeople not just equipped with technical skills, but with a deep commitment and passion
                            for what they do. Future citizens endowed with a conscience, inspired to give back and help
                            bring peace and harmony to humanity throughout their careers.</li>
                    </ul> -->

                    <h3 class="cu-clr-main">Join Us on this Cinematic Journey</h3>
                    <ul>
                        <li>
                            DBIMA is more than a school; it is a thriving community where dreams flourish, creativity blossoms, and opportunities abound. For further details, reach out to us at
                            <a href="mailto:info@dbima.eu" class="cu-clr-3">info@dbima.eu</a>  
                        </li>
                        <li>
                            Embark on your cinematic journey with Don Bosco International Media Academy - an adventure awaits!
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>