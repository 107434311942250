export const details = [
    {
        id: 1,
        name: 'BTS Métiers de l’Audiovisuel',
        url: 'bts-en-alternance',
        p_alt: 'A person working on Editing a video',
        s_alt: 'A person working on Editing a video',
        mini_description:
            'Le technicien supérieur en audiovisuel option "métiers du montage et de la postproduction" a la charge de concevoir, mettre en œuvre, mettre en forme, réaliser le montage et la postproduction d\'une œuvre ou d\'un produit audiovisuel.',
        category: 'Degree Program',
    },
    {
        id: 2,
        name: 'European Bachelor of Arts in Cinema and Audiovisual',
        url: 'bachelor-of-arts-in-cinema-and-audiovisual',
        p_alt: 'Some sort of a content shooting in a setup background',
        s_alt: 'Picture of a Film Making Camera',
        mini_description:
            'The main goal of our bachelor program is to create a new generation of professionals with advanced theoretical and most importantly, practical skills. ',
        category: 'Bachelor program',
    },
    {
        id: 3,
        name: 'European Master of Arts in Cinema and Audiovisual', //'Master of Arts in Cinema and Audiovisual',
        url: 'master-of-arts-in-cinema-and-audiovisual',
        p_alt: 'A close-up picture of a film making camera',
        s_alt: 'A crew making a film in a green screen studio',
        mini_description:
            "The main goal of our master program is to develop a personal vision for our students through a professional application of the student's end of year project.",
        category: 'Master program',
    },
    {
        id: 4,
        name: 'One Year Film Program',
        url: 'one-year-film-program',
        p_alt: 'A close-up picture of a film making camera with a light green background',
        s_alt: 'Videographer making a film with an actress at the beach',
        mini_description:
            'Advanced course in visual media, including concept creation, story development, scriptwriting, pre-production, production (filming) and post-production (editing, VFX and audio). ',
        category: 'Diploma',
    }

];
