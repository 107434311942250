<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/programs">Our Programs</a>
                </li>
                <li>{{program_name}}</li>
            </ul>
            <h2 class="cs-display-middle">{{program_name}}</h2>
        </div>
        <img src="assets/img/site/illustrations/teaching_new.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="courses-details-area pb-5">
    <div class="container mb-3">
        <div class="row">
            <div class="col-lg-8 col-md-12">

                <div class="products-details-tabs">
                    <ngx-tabset>

                        <ngx-tab tabTitle="Presentation">
                            <div class="courses-overview">
                                <div class="courses-details-image-style-two text-center">
                                    <img src="assets/img/site/programs/p1/p.jpg" alt="{{programDetails.p_alt}}">
                                </div>
                                <div class="courses-details-desc-style-two">
                                    <h3>Description:</h3>
                                    <p>Professionnel spécialisé, le monteur intervient sous la responsabilité d’un réalisateur, d’un producteur, d’un journaliste et/ou d’un responsable de post production ou d’un chef de projet effets visuels. </p>
                                    <p>Intégré dans l’équipe de réalisation d’un programme audiovisuel, son intervention s’articule avec celle des autres professionnels spécialisés engagés dans le projet. Il répond à une demande pour laquelle lui sont spécifiés des besoins et précisées des contraintes. </p>

                                    <p>Son métier requiert des compétences professionnelles, mais aussi des qualités humaines : créativité, sensibilité artistique, curiosité technologique, capacité à s’intégrer dans une équipe de production et dans une entreprise, à communiquer, à respecter une ligne éditoriale, à développer une argumentation et à convaincre.</p>

                                    <h3>A l’issue de sa formation, l’élève devra POUVOIR être capable de :</h3>
                                    <ul class="description-features-list ">
                                        <li>Sélectionner, choisir les éléments (images et sons) issus du tournage.</li>
                                        <li>Assurer la construction narrative et participer à l’écriture d’un projet (reportage, documentaire, fiction, institutionnel, magazine, clip, publicité).</li>
                                        <li>Analyser et interpréter le scénario.</li>
                                        <li>Agencer et assembler les images et les sons (montage son et/ou image).</li>
                                        <li>Effectuer et comprendre les différents effets spéciaux, habillages, traitements et retouches d’images demandés dans l’univers esthétique et fonctionnel du projet (jingles, génériques, trucages 2D/3D, etc.).</li>
                                    </ul>

                                    <h3>Secteurs d’activités :</h3>
                                    <ul class="description-features-list ">
                                        <li>Société de production audiovisuelle ou cinématographique. </li>
                                        <li>Prestataire technique (postproduction- communication-événement). </li>
                                        <li>Chaîne de télévision généraliste, thématique, nationale, régionale, locale, WebTV. </li>
                                        <li>Sociétés audiovisuelles liées aux nouvelles technologies. </li>
                                    </ul>

                                    <h3>Emplois concernés :</h3>
                                    <ul class="description-features-list ">
                                        <li>Monteur </li>
                                        <li>Assistant monteur </li>
                                        <li>Monteur truquiste </li>

                                    </ul>

                                    <h3>En fonction de son potentiel, de formations complémentaires et de l’expérience professionnelle acquise, le titulaire du diplôme</h3>
                                    <ul class="description-features-list ">
                                        <li>Chef monteur</li>
                                        <li>Étalonneur</li>
                                        <li>Stéréographies</li>
                                        <li>Infographiste</li>
                                        <li>Réalisateur</li>                                        
                                    </ul>

                                    <h3>Le Programme de notre BTS</h3>
                                    <p>Le programme est défini par le ministère de l’Éducation Nationale et précisé par la Direction générale de l’enseignement supérieur et de l’insertion professionnelle dans sa circulaire de septembre 2016 sur les Métiers du montage et de la postproduction.</p>
                                    <p>Référentiel de BTS métiers de l’audiovisuel spécialité Montage et Post-production (BTS mav MPP) <br/>
                                        <a href="https://eduscol.education.fr/sti/formations/bts/bts-metiers-de-laudiovisuel-mav" target="_blank">https://eduscol.education.fr/sti/formations/bts/bts-metiers-de-laudiovisuel-mav</a>
                                    </p>
                                    <p>France compétences RNCP 37020 <br/>
                                        <a href="https://www.francecompetences.fr/recherche/rncp/37020" target="_blank">https://www.francecompetences.fr/recherche/rncp/37020</a>
                                    </p>
                                    <h3>Les disciplines enseignées sont :</h3>
                                    <ul class="description-features-list ">
                                        <li>Culture Artistique et Audiovisuelle </li>
                                        <li>Technique des Équipements et Supports </li>
                                        <li>Sciences Physique appliquées à l’Audiovisuel </li>
                                        <li>Anglais </li>
                                        <li>Environnement Économique et Juridique </li>
                                        <li>Épreuves pratiques dites Techniques et Mise en œuvre </li>
                                        <li>Rapport de stage sur l’activité en entreprise et dossier de production en lien avec le PCP </li>
                                    </ul>

                                    <p>Diplôme de l’Éducation Nationale niveau III, le BTS Audiovisuel permet la poursuite d’études dans un cursus supérieur niveau II dans la même spécialité, comme une Licence de cinéma à l’Université, une licence pro.</p>

                                    <p>Un BTS Audiovisuel vous permet également de présenter les concours aux grandes écoles de cinéma publiques à savoir La FEMIS ou l’ENS Louis-Lumière. </p>

                                    <h4>DBIMA est une Unité de Formation d’Apprentis rattachée à un Centre de formation d’Apprentis </h4>

                                    <h3>DBIMA dispense au jeune travailleur sous contrat d’apprentissage :</h3>
                                    <ul class="description-features-list ">
                                        <li>Une formation générale, d’une part sur le programme établi par l’éducation nationale </li>
                                        <li>Une formation technologique et pratique qui doit compléter la formation reçue en entreprise et s’articuler avec elle, en utilisant tous les moyens techniques de l’école.</li>
                                    </ul>

                                    <h5>La durée de la formation</h5>
                                    <p>Contrat d’apprentissage de 2 années dont 1400 heures réparties sur 2 ans dans notre établissement.</p>

                                    <h5>Le rythme de l’alternance</h5>
                                    <p>A DBIMA, il est de deux semaines en entreprise pour 2 semaines en centre de formation DBIMA.</p>
                                    <p>L’étudiant possède le statut de salarié de l’entreprise contractant le contrat d’apprentissage, et, de ce fait ne bénéficie que des congés payés et non des vacances scolaires. Le rythme de travail, en entreprise comme en centre, est de 35 heures hebdomadaires. Il bénéficie de la protection sociale du salarié d’entreprise.</p>

                                    <h5>L’examen</h5>
                                    <p>L’apprenti est tenu de se présenter aux épreuves conduisant au titre prévu par son contrat d’apprentissage.</p>
                                    <p>L’employeur a pour sa part, l’obligation de laisser à l’apprenti le temps nécessaire pour y participer.</p>


                                    <h5>Admission</h5>
                                    <p>Les épreuves d’admission sont ouvertes aux titulaires, a minima, d’un baccalauréat général ou technologique toutes options.</p>
                                    <p>L’option montage post-production est une formation ou la culture générale est importante. Les élèves de section littéraire y ont toute leur place. Toutefois, il est à noter que l’examen de sciences physiques appliquées requiert un niveau scientifique minimum. Le BTS est un diplôme exigeant qui nécessite d’avoir un niveau scolaire suffisant pour réussir.</p>
                                    <p class="fw-bold">L’équipe pédagogique DBIMA se réunit pour statuer sur chaque candidat. Elle prend en compte</p>
                                    <ul class="description-features-list ">
                                        <li>Les bulletins scolaires de terminale : notes dans les matières significatives, appréciations des enseignants, absences. Le candidat doit montrer qu’il n’est pas réfractaire aux études. </li>
                                        <li>Les épreuves complémentaires de sélection que DBIMA jugera utile de mettre en place. </li>
                                        <li>La solidité du projet professionnel présenté lors d’un entretien individuel. </li>
                                    </ul>
                                    <!-- <hr>
                                    <p>
                                        Type de Formation : <strong>Initiale</strong>
                                        <br>Option : <strong>Montage et post-production</strong>
                                        <br>Langue : <strong>Français </strong>
                                        <br>Durée : <strong>2 ans</strong>
                                        <br>Niveau : <strong>Être titulaire d’un Bac</strong>
                                        <br>Diplôme :<strong>Diplôme l’état</strong>
                                        <br>Présence : <strong>Deux semaines en entreprise et 2 semaines en centre
                                            de formation DBIMA</strong>
                                    </p> -->
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Programme">

                            <div class="">
                                <div class="container courses-curriculum-contain" style="margin-left: 0rem;">
                                    <!-- <hr class="cu-hr"> -->
                                    <div class="faq-accordion courses-details-desc-style-two">
                                        <h3 style="font-size: large;">PROGRAMME SPECIFIQUE AU BTS AUDIOVISUEL OPTION MONTAGE EN ALTERNANCE :</h3>
                                        <div class="ml-2">
                                            <p><span class="fw-bold"> MODULE 1 : </span> Configuration technique d’une station de montage.</p>
                                            <p><span class="fw-bold"> MODULE 2 : </span> Pratique et maitrise du montage. </p>
                                            <p><span class="fw-bold"> MODULE 3 : </span> Habillage et trucage. </p>
                                            <p><span class="fw-bold"> MODULE 4 : </span> Le projet de synthèse professionnelle : les EPS. </p>
                                            <p><span class="fw-bold"> MODULE 5 : </span> Culture audiovisuelle et artistique. </p>
                                            <p><span class="fw-bold"> MODULE 6 : </span> Sciences physiques, technologies des équipements audiovisuels. </p>
                                            <p><span class="fw-bold"> MODULE 7 : </span> Environnement économique et juridique. </p>
                                            <p><span class="fw-bold"> MODULE 8 : </span> Anglais technique et professionnel. </p>
                                        </div>
                                        <!-- <accordion>
                                            <accordion-group heading="Year 1">
                                                <ul>
                                                    <li><strong>CAA Histoire de l'Art BTS1</strong></li>
                                                    <li><strong>CAA Histoire du Cinema BTS1</strong></li>
                                                    <li><strong>CAA Histoire de la Télévision BTS1</strong></li>
                                                    <li><strong>CAA Histoire des médias BTS1</strong></li>
                                                    <li><strong>CAA Analyse d'une œuvre BTS1</strong></li>
                                                    <li><strong>CAA Analyse d'un film BTS1</strong></li>
                                                    <li><strong>CAA Sociologie des médias BTS1</strong></li>
                                                    <li><strong>EEJ BTS1</strong></li>
                                                    <li><strong>TMO BTS1</strong></li>
                                                    <li><strong>TES BTS1</strong></li>
                                                    <li><strong>Anglais BTS1</strong></li>
                                                    <li><strong>PCP et Jury BTS1</strong></li>
                                                    <li><strong>Rapport de Stage et Jury BTS1</strong></li>
                                                    <li><strong>EEJ ORAL BTS1</strong></li>
                                                    <li><strong>Examen Blanc BTS1</strong></li>
                                                </ul>
                                            </accordion-group>
                                            <accordion-group heading="Year 2">
                                                <ul>
                                                    <li><strong>CAA Histoire de l'Art BTS2</strong></li>
                                                    <li><strong>CAA Histoire du Cinema BTS2</strong></li>
                                                    <li><strong>CAA Méthodologie de travail BTS2</strong></li>
                                                    <li><strong>CAA Analyse d'une œuvre BTS2</strong></li>
                                                    <li><strong>CAA Analyse d'un film BTS2</strong></li>
                                                    <li><strong>CAA Sociologie des médias BTS2</strong></li>
                                                    <li><strong>EEJ BTS2</strong></li>
                                                    <li><strong>TMO BTS2</strong></li>
                                                    <li><strong>TES BTS2</strong></li>
                                                    <li><strong>Anglais BTS2</strong></li>
                                                    <li><strong>PCP et Jury BTS2</strong></li>
                                                    <li><strong>Rapport de Stage et Jury BTS2</strong></li>
                                                    <li><strong>EEJ ORAL BTS2</strong></li>
                                                </ul>
                                            </accordion-group>
                                        </accordion> -->
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Admission ">
                            <div class="courses-requirements">

                                <div class="courses-details-desc-style-two">
                                    <h3>BTS Métiers de l’Audiovisuel Option Montage et Post Production</h3>
                                    <ul class="description-features-list ">
                                       <li>Baccalauréat Série Générale ou Technologique (Copie du diplôme à fournir) ou copie des bulletins scolaires du 1er et 2nd trimestre de Terminale Série Générale ou Technologique </li>
                                       <li>Lettre de motivation </li>
                                       <li>Test de niveau en Sciences Physiques </li>
                                       <li>Test de niveau en Français/Analyse de document </li>
                                       <li>Entretien individuel de motivation </li>
                                       <li>Si possible, lettre d’engagement d’un employeur en vue du contrat d’apprentissage </li>
                                    </ul>

                                    <br>
                                    <p>Nombre de places limitées : 12 places</p>

                                    <h3>Mise en liste d’attente si :</h3>
                                    <ul class="description-features-list ">
                                       <li>Résultats inférieurs à la moyenne aux tests de niveau </li>
                                       <li>Pas de résultats officiels de réussite au Baccalauréat </li>
                                    </ul>

                                    <p>Vous pourrez nous adresser toutes réclamations à l’adresse électronique suivante : <a href="mailto:support@dbi-ecademy.fr">support@dbi-ecademy.fr</a> ou <a href="mailto:info@dbima.eu ">info@dbima.eu </a></p>
                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 " style="padding-top: 100px;">
                <div class="courses-sidebar-information">
                    <ul class="info ">
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-time "></i> Duration</span> 2 Years
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-distance-learning "></i> Modules</span> 8
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-html "></i> Language</span> French
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-certification "></i> Certificate</span> Yes
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box ">
                        <a routerLink="/register-for/{{program_id}}/{{program_url}}" class="default-btn ">Apply Now<i
                                class="flaticon-verify ml-2"></i><span></span></a>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pt-100 pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>More Courses You Might Like</h2>
        </div>

        <div class="row ">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program2-mini></app-program2-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program3-mini></app-program3-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program4-mini></app-program4-mini>
                </div>
            </div>
        </div>
    </div>
</section>
