<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Questions</h2>

        </div>
        <img src="assets/img/site/illustrations/faq.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px; width: auto" class="cs-display-bottomleft pt-10 cs-hide-small" />
    </div>

    <div class="shape9">
        <img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon" />
    </div>
</div>

<section class="faq-area pb-100">
    <div class="container">

        <div class="row pb-5">
            <div class="col-sm-6 col-md-6 col-lg-9"></div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <select class="form-control cu-pointer cs-border" id="lang_code_selector" *ngIf="faqDiv[0]" onchange="changeLangCode(this)">
                    <option value="en" selected>English</option>
                    <option value="fr" >French</option>
                </select>
                <select class="form-control cu-pointer cs-border" id="lang_code_selector" *ngIf="faqDiv[1]" onchange="changeLangCode(this)">
                    <option value="en" >English</option>
                    <option value="fr" selected>French</option>
                </select>
            </div>
        </div>

        <div class="container" *ngIf="faqDiv[0]">
            <div class="faq-accordion">
                <accordion>
                    <accordion-group heading="What is DBIMA?">
                        <p>DBIMA is a Private School for Higher Education registered at the « Rectorat de Paris » and the « Académie de Paris ». It depends of the « Ministère de l’Education Nationale » (French Ministry of Education)</p>
                        <p> DBIMA is a Cinema and Audiovisual school with an international program and a French program (BTS).</p>
                        <p> DBIMA Bachelor and master’s degrees are following the European Certification process and will be certificated be EABHES as European Bachelor and European Master in July 2022.</p>
                        <p> DBIMA BTS Degree is a French National Bac+2 Diploma.</p>
                    </accordion-group>
                    <accordion-group heading="Who are DBIMA’s students?">
                        <p>DBIMA students are coming from all over the world. Passionate in filmmaking, they aim to become specialized professionals and to get integrated right after their studies in the professional world. There is no age limit and students
                            must pass their High School Diploma as the minimum of requirement. Most of our students are between 18 and 29 years old.</p>
                    </accordion-group>
                    <accordion-group heading="What type of programs DBIMA offers?">
                        <ul>
                            <li>The Master of Arts in Cinema and Audiovisual program is a 2 years program.</li>
                            <li>The Bachelor of Arts in Cinema and Audiovisual is a 3 years program.</li>
                            <li>The One Year program is 1 year of introduction to the basics of filmmaking.</li>
                            <li>The BTS Métiers de l’Audiovisuel en apprentissage is a 2 years program.</li>
                        </ul>
                        <p>Workshops and Summer-camps are 1 to 3 weeks length.</p>
                    </accordion-group>
                    <accordion-group heading="What is the size of the class?">
                        <p>To preserve a high quality in Education, DBIMA is limiting each class to 12 students (10 students in Master).</p>
                    </accordion-group>
                    <accordion-group heading="Is there Direct entrance in the programs?">
                        <p>Direct entrance in 2nd year or 3rd year in both Bachelor and Master Program is possible. Please refer to the requirements page of each degree on our website.</p>
                    </accordion-group>
                    <accordion-group heading="When is the admissions period?">
                        <p>Admissions begins in spring and goes until summer depending on available places. DBIMA is limiting the class to 12 students for a high quality of education, so admissions usually end early after its opening.</p>
                    </accordion-group>
                    <accordion-group heading="How do I apply?">
                        <p>Please refer to our website pages for requirements. You can apply directly from our website.</p>
                    </accordion-group>
                    <accordion-group heading="Do I need to understand French in order to go to the international programs?">
                        <p>Our Bachelor, Master and One Year programs are entirely taught in English. So, no need to understand French</p>
                    </accordion-group>
                    <accordion-group heading="Do I need to be fluent in English to follow the international programs?">
                        <p>No. Our Bachelor, Master and One Year programs are entirely taught in English. Students have to understand English as a basic language to follow the program. It is not mandatory to be a native English speaker, and English courses
                            are part of all other programs.</p>
                    </accordion-group>
                    <accordion-group heading="I am French, can I apply to the international programs?">
                        <p>Yes, of course. Courses are taught in English, and you will enjoy the variety of culture and nationality during your studies and your experiences on sets.</p>
                    </accordion-group>
                    <accordion-group heading="Program is in English, but can I direct a movie in my native language during my studies?">
                        <p>Yes, of course. Only courses are taught in English. Main difficulty is to cast actors in a foreign language. Our Acting Coach Professor can help you for that.</p>
                    </accordion-group>
                    <accordion-group heading="When does the academic year start?">
                        <p>For Bachelor and Master program, the academic year starts on mid of October. For Master students who need a review, a refreshing week of intensive courses is scheduled on the second week of October. The BTS program starts on early
                            September
                        </p>
                    </accordion-group>
                    <accordion-group heading="Do you offer scholarships?">
                        <p>According to the Don Bosco philosophy, DBIMA offers tuition reductions (up to 25%) to a limited number of students who demonstrate financial need and personal difficulties. A special committee examine each demand of early applicants.</p>
                    </accordion-group>
                    <accordion-group heading="For whom the one-year program is designed for?">
                        <p>Many students are considering Filmmaking studies but are not 100% sure of their choice or of the reality of Cinema and its different disciplines. The one-year program gives a perspective of it and, with added works, can offer a
                            2nd year direct entrance. Students will learn the basics and will participate in shoots during the production period but won’t direct an End of Year Project.
                        </p>
                    </accordion-group>
                    <accordion-group heading="I am 18 years old and passed my High School Diploma, should I go for the BTS or the Bachelor program?">
                        <p>The BTS program is reserved for French students. Most of the program (designed by the French Ministry of Education) is theoretical and the specialization in Editing and Postproduction starts at the first hours of class. Specifically
                            designed to train editors, the program does not include, for example, scriptwriting courses, directing courses… French students are trained to integrate immediately the professional audiovisual world as editors (Communication,
                            TV, Audiovisual department…). </p>
                        <p> All courses are taught in French.</p>
                        <p>The Bachelor program is an European bachelor program for Cinema professionals and Future Filmmakers. Based on a « Learning by doing » pedagogy, practical classes are held 3 times a week at a minimum. Organized as a professional
                            team, students are practising with feature film equipment and are trained to integrate the feature film market. All the disciplines which compose Filmmaking are explored and taught. All the professors are professionals in activity
                            are passionate of transmitting their knowledge to young filmmakers. Each year, the production period allows students to share, write, direct, produce, light, sound, their own movies.
                        </p>
                    </accordion-group>
                    <accordion-group heading="When should I decide on a Major/Specialization during the Bachelor or Master program?">
                        <p>The choice of the Major has to be done on March 2nd year in the Bachelor program and in beginning of October for the 2nd year of Master.</p>
                    </accordion-group>
                    <accordion-group heading="Do you provide contact for Contract d'Apprentissage (BTS)?">
                        <p>DBIMA is helping students to compose their application files (CV, Motivation letter…) and will provide to students a list of professional production companies based in Paris as well as a private access to a professional Database.</p>
                    </accordion-group>
                    <accordion-group heading="Do you provide contacts with professional shoots?">
                        <p>DBIMA program is fully absorbing student weekly time during the year. We encourage students to apply for internship during summer break and some reserved weeks in the year schedule. Added ECTS Credits are allocated for Internships.
                            We advise students about professional companies and each student has a personal access to a professional database which is listing all the shoots on-going or in preparation in France.</p>
                    </accordion-group>
                    <accordion-group heading="Where is DBIMA Located?">
                        <p>DBIMA is located in the centre of Paris, 5mn walk from Metro Station « Alexandre Dumas » or « Buzenwal ». Studying at DBIMA will allow students to enjoy the marvellous city of Paris.
                        </p>
                    </accordion-group>
                    <accordion-group heading="Do you offer accommodation?">
                        <p>Thanks to the Don Bosco Network, DBIMA can offer accommodations close to the school, in a safety environment, at a competitive price. For more information contact: <a href="mailto:info@dbima.eu">info@dbima.eu</a> </p>
                    </accordion-group>
                    <accordion-group heading="What is the link between DBIMA and the Catholic Church? Should I be part of it to become a DBIMA Student?">
                        <p>DBIMA is a private Cinema school open to any student with a passion in filmmaking. We accept students from all origins and all religions as Filmmaking is our unique passion.</p>
                        <p>We are simply following the Education philosophy set by Don Bosco who taught that « educators should act like caring friends; always kind and gentle. ». </p>
                        <p>
                            As our students, our teachers are coming from all over the world and from different culture (France, USA, Egypt, Russia, Iran, India, Sri Lanka, Spain…) All of them were selected only for their professionalism and their former experience in Filmmaking
                            teaching.
                        </p>
                        <p>DBIMA is part of the Don Bosco Education Network which includes more than 8000 schools in 132 countries (Vietnam, Italy, Spain, Morocco, India ….)</p>
                    </accordion-group>
                    <accordion-group heading="Where can I find more information?">
                        <p>After exploring our website, if you need any added information, please send a mail to: <a href="mailto:info@dbima.eu">info@dbima.eu</a></p>
                    </accordion-group>
                </accordion>
            </div>
        </div>

        <div class="container" *ngIf="faqDiv[1]">
            <div class="faq-accordion">
                <accordion>
                    <accordion-group heading="Qu’est-ce que DBIMA ?">
                        <p>DBIMA est un Établissement d’Enseignement Supérieur Privé Hors Contrat enregistré au Rectorat de Paris et à l’Académie de Paris et dépend du Ministère de l’Éducation Nationale.</p>
                        <p>DBIMA est une école de Cinéma et Audiovisuel et propose un programme international et un programme français de BTS.</p>
                        <p>Le Bachelor et le Master de DBIMA sont en cours de certification de diplôme au niveau européen par EABHES. En juillet 2022, cette certification sera achevée et les diplômes seront officiellement un Européen Bachelor of Arts et
                            Européen Master of Arts. Le BTS Métiers de l’Audiovisuel est un diplôme d’État de niveau Bac+2
                        </p>
                    </accordion-group>
                    <accordion-group heading="Qui sont les étudiants de DBIMA ?">
                        <p>Les étudiants de DBIMA viennent du monde entier et sont passionnés par le Cinéma et la fabrication de films.</p>
                        <p>Ils ont choisi de devenir professionnels et d’intégrer le marché dès la fin de leurs études.</p>
                        <p>Il n’y a pas d’âge limite pour postuler à DBIMA et le Baccalauréat est le niveau minimum requis pour intégrer l’un de nos programmes. Nos étudiants ont en moyenne de 18 à 29 ans.</p>
                    </accordion-group>
                    <accordion-group heading="Quels sont les diplômes proposés à DBIMA ?">
                        <p>Le Master of Arts in Cinéma and Audiovisuel est un programme en 2 ans. Le Bachelor of Arts in Cinéma and Audiovisuel est un programme en 3 ans. Le One Year Program est une introduction aux bases du Cinéma, théoriques et techniques,
                            sanctionné par un certificat DBIMA. </p>
                        <p>Ces trois programmes sont enseignés en Anglais.</p>
                        <p>Le BTS Métiers de l’Audiovisuel en apprentissage est un programme en apprentissage sur 2 ans. Les workshops et stages d’été durent de 1 à 3 semaines.
                        </p>
                    </accordion-group>
                    <accordion-group heading="Quelle est la taille d’une classe à DBIMA ?">
                        <p>Pour préserver une haute qualité d’enseignement, les classes sont limitées à 12 élèves au maximum, 10 élèves en classe de Master.</p>
                    </accordion-group>
                    <accordion-group heading="Est-il possible d’entrer directement en 2e ou 3e année ?">
                        <p>Les entrées directes en 2e ou 3e années sont possibles en Bachelor et Master Program. Merci de vous référer au page « Rééquipements » de chaque diplôme sur notre site web </p>
                    </accordion-group>
                    <accordion-group heading="Quand débutes la période d’admission ?">
                        <p>La période d’admission débute habituellement au printemps et s’étale jusqu’à l’été. Toutefois, comme DBIMA limite le nombre d’étudiants à 12 élèves par classe, les places disponibles sont peu nombreuses et leur nombre diminue très
                            vite dès l’ouverture des admissions. </p>
                        <p>Nous vous conseillons donc de valider votre demande au plus tôt et nous les réceptionnons les candidatures toute l’année.</p>
                    </accordion-group>
                    <accordion-group heading="Comment puis-je postuler à DBIMA ?">
                        <p>Vous pouvez enregistrer votre demande sur notre site web.</p>
                    </accordion-group>
                    <accordion-group heading="Dois-je maîtriser parfaitement l’Anglais pour pouvoir suivre les programmes de Bachelor ou Master ?">
                        <p>Nos programmes de Bachelor et Master sont enseignés en Anglais. Les étudiants doivent donc comprendre et parler l’anglais à un niveau scolaire équivalent au Baccalauréat.</p>
                        <p>Il n’est pas obligatoire d’être natif anglo-saxon ou de parler couramment la langue anglaise. Un test d’anglais peut vous être proposé lors de votre inscription.</p>
                    </accordion-group>
                    <accordion-group heading="Est-il nécessaire de parler et comprendre le français pour postuler à DBIMA ?">
                        <p>Non. Nos programmes de Bachelor et Master sont enseignés en Anglais. L’ensemble de la pédagogie et les cours se déroulent en anglais.</p>
                    </accordion-group>
                    <accordion-group heading="Je suis français, puis je postuler au programme international?">
                        <p>Oui, bien sûr. En suivant l’un de nos programme international, vous vivrez une expérience de partage de cultures et de passion commune.</p>
                    </accordion-group>
                    <accordion-group heading="Les programmes sont en Anglais, mais je souhaite réaliser mon film dans ma langue native, est-ce possible ?">
                        <p>Oui, bien sûr. Votre difficulté sera de trouver et caster des acteurs capables de jouer dans votre langue native. Nos professeurs d’Acting et coaches d’acteurs pourront vous aider dans ce sens. </p>
                    </accordion-group>
                    <accordion-group heading="Quand débute l’année scolaire ?">
                        <p>Pour les programmes de Bachelor et de Master ; la rentrée scolaire est située mi-octobre, avec une semaine de mise à niveau début octobre pour les étudiants de master qui en ont besoin. Le programme de BTS commence début septembre.
                        </p>
                    </accordion-group>
                    <accordion-group heading="Proposez-vous une Bourse d’Études à DBIMA ?">
                        <p>La philosophie de Don Bosco est l’éducation pour tous et un effort envers les défavorisés. DBIMA permet à un nombre limité d’étudiants d’obtenir une bourse d’études s’ils rencontrent des difficultés financières ou personnelles.
                            Un comité examine chaque demande et peut octroyer une bourse d’études à hauteur de 10% à 25% des frais de scolarités.</p>
                    </accordion-group>
                    <accordion-group heading="Pour qui est proposé le programme en 1 an ?">
                        <p>Beaucoup d’étudiants envisagent des études en Cinéma mais ne connaissent pas les possibilités et les débouchées professionnelles possibles. </p>
                        <p>Le programme en 1 an donne les perspectives d’une professionnalisation dans le Cinéma en introduisant les bases théoriques et techniques des disciplines du Cinéma. </p>
                    </accordion-group>
                    <accordion-group heading="Je viens d’avoir 18 ans et j’ai obtenu mon baccalauréat. Quel cursus dois-je choisir, plutôt le BTS ou le Bachelor ?">
                        <p>Le programme de BTS de l’Éducation Nationale est très théorique spécifiquement tourné vers le métier de Monteur Audiovisuel. </p>
                        <p>Cette spécialisation débute dès les premières heures d’enseignement. Le programme ne comporte pas, par exemple, de cours de scénario ou de réalisation... Le but du BTS est de former des monteurs truquistes aptes à intégrer directement
                            le monde professionnel de l’Audiovisuel, la Communication, Les Nouveaux Médias ou la Télévision. Tous les cours sont en français.</p>
                        <p>Le programme de Bachelor forme les Cinéastes de demain, professionnels et spécialisés dans toutes les disciplines du Cinéma. Basé sur la pédagogie du « Learning by doing » les cours pratiques représentent 3 jours par semaine à
                            minima.
                        </p>
                        <p>Organisé comme une équipe de tournage, chaque classe utilise les mêmes moyens techniques et l’organisation d’une équipe de long métrage professionnelle. Toutes les disciplines du Cinéma sont enseignées dans un tronc commun et la
                            3e année est une spécialisation. </p>
                        <p>Tous les professeurs sont des professionnels en activité et ont rejoint DBIMA pour transmettre leur passion et leur expérience professionnelle. A la fin de chaque année, la période de production des films de fin d’année permet
                            aux étudiants d’écrire, produire, tourner, éclairer et post produire leur propre projet de court métrage.</p>
                    </accordion-group>
                    <accordion-group heading="Quand se décide la spécialisation dans le programme Bachelor ou Master ?">
                        <p>Le choix de spécialisation en Bachelor se décide en mars de la 2nde année et, pour les Master, en début de 2nde année</p>
                    </accordion-group>
                    <accordion-group heading="Proposez-vous le Contrat d'Apprentissage (BTS) ?">
                        <p>DBIMA aide les étudiants à formuler leur recherche et à rédiger leur CV, lettre de motivation …. Une liste des sociétés de production basées à Paris est fournie, ainsi qu’un accès privé à une base de données professionnelle.</p>
                    </accordion-group>
                    <accordion-group heading="Fournissez-vous des contacts avec des tournages professionnels ?">
                        <p>Les programmes de DBIMA impliquent des études à plein temps. Quelques possibilités de dates de stages professionnels sont prévues dans les emplois du temps.</p>
                        <p>Nous encourageons les étudiants à effectuer des stages en sus de leurs études en allouant de crédites ECTS supplémentaires à ces périodes de stages et en conseillant les étudiants sur leurs recherches. Un accès personnel à une
                            base de données professionnelle permet aux étudiants de savoir quels sont les films en préparation, en tournage, ou en projet. </p>
                    </accordion-group>
                    <accordion-group heading="Où est situé DBIMA ?">
                        <p>DBIMA est située au centre de Paris à 5minutes à pied des stations de Métro « Alexandre Dumas » et « Buzenwal ». Étudier à DBIMA permet aux étudiants de participer à la vie culturelle parisienne.
                        </p>
                    </accordion-group>
                    <accordion-group heading="Proposez-vous des logements ?">
                        <p>DBIMA fait partie du réseau Don Bosco et, à ce titre, propose des solutions d’hébergement étudiant proche de l’école à tarifs compétitifs et préférentiels. Pour toute information contactez : <a href="mailto:info@dbima.eu">info@dbima.eu</a></p>
                    </accordion-group>
                    <accordion-group heading="Quel est le lien entre l’Église Catholique et DBIMA ?Doit-on faire partie de l’Église Catholique pour intégrer DBIMA ?">
                        <p>DBIMA est une école de Cinéma privée ouverte à tous les étudiants passionnés par le Cinéma. Nous acceptons les étudiants de toutes les origines et de toutes le confessions. Le Cinéma est notre unique passion.
                        </p>
                        <p>Nous suivons simplement la philosophie d’éducation de Don Bosco qui dit que « les enseignants doivent agir comme des proches compréhensibles, toujours ouverts et bienveillants » Comme nos étudiants, nos enseignants viennent de
                            tous les horizons et de toutes les cultures (France, USA, Égypte, Russie, Iran, Inde, Sri Lanka, Espagne…) Tous ont été sélectionnés pour leur professionnalisme, et leur expérience dans l’enseignement du Cinéma.
                        </p>
                    </accordion-group>
                    <accordion-group heading="Ou puis je trouver plus d’informations ?">
                        <p>Si, après avoir visité l’intégralité de notre site, vous souhaitez plus d’informations, merci de nous contacter à : <a href="mailto:info@dbima.eu">info@dbima.eu</a></p>
                    </accordion-group>
                    <accordion-group heading="">

                    </accordion-group>
                    <accordion-group heading="">

                    </accordion-group>
                    <accordion-group heading="">

                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>
</section>