import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
    selector: 'app-program2-mini',
    templateUrl: './program2-mini.component.html',
    styleUrls: ['./program2-mini.component.scss']
})
export class Program2MiniComponent implements OnInit {

    programDetails = details[1];

    constructor() { }

    ngOnInit(): void {
    }

}
