import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-mini-program-view',
  templateUrl: './mini-program-view.component.html',
  styleUrls: ['./mini-program-view.component.scss']
})
export class MiniProgramViewComponent implements OnInit {
    mini_program_count = 4;
    mini_program_state = Array;
    url_data;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        var mini_id = this.url_data.mini_id;
        var arrayIndex = mini_id - 1;

        for (let index = 0; index < this.mini_program_count; index++) {
            if(index == arrayIndex){
                this.mini_program_state[index] = true;
            }else{
                this.mini_program_state[index] = false;
            }
        }
    }
}
