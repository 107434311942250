<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/programs">Cookie & Privacy Policy</a>
                </li>
            </ul>
            <h2>Cookie & Privacy Policy</h2>
        </div>
        <img src="assets/img/illustrations/privacy_protection.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>

</div>

<div class="privacy-policy-area pb-100">
    <div class="container">
        <div class="row cu-dbima-bg" style="background-size: 200px 200px;">
            <div class="col-lg-8 col-md-12">
                <div class="privacy-policy-content">

                    <p class="cu-clr-main"><i>This Privacy Policy was last updated on <strong>June 01, 2022</strong>
                            .</i></p>

                    <p>The personal data collected in particular via cookies on the site is processed by:</p>
                    <blockquote class="blockquote cs-white">
                        <p>
                            NGO – Don Bosco Intuition Entertainers Media<br> 393 bis Rue Des Pyrénees, 75020 Paris.<br> SIRET: 334 518 040 000 38<br>
                        </p>
                        <hr>
                        <p>
                            Don Bosco International Media Academy<br> 75 Rue Alexandre Dumas, 75020 Paris<br>

                        </p>
                    </blockquote>

                    <p>Hereinafter referred as <b>“DBIMA”</b>. When consulting our <b><a
                                href="https://dbima.eu/">dbima.eu</a></b> site, cookies are placed by DBIMA and/or third parties on your computer, mobile or tablet.This page allows you to better understand how cookies work and how to use current tools
                        to configure them.</p>


                    <h3>What is a cookie ?</h3>

                    <p>A cookie is a small text file placed on your computer when visiting a site or consulting an advertisement. Their main purpose is to collect information relating to your browsing on the sites and to send you personalized services. Different
                        types of cookies can be used on our site: http cookies, cookies in local storage, flash cookies, fingerprinting, pixels or other tracking identifiers...
                    </p>
                    <p>Our site is designed to be particularly attentive to the needs and expectations of our customers. This is among other things why we use cookies in order, for example, to identify you and access your account, manage your shopping cart,
                        memorize your consultations and personalize the offers that we offer you and the advertisements that you view on our website. site or off site.</p>

                    <h3>Why do we use cookies?</h3>
                    <p>Cookies have different purposes. Some are necessary for your use of our site, others have more ancillary purposes.</p>

                    <h3>Cookies necessary for the operation of the site and our services</h3>
                    <p>
                        These are cookies necessary for the operation of our site or the services you request. They allow you to use the main features of our site (for example course registration, access to your account, access to our gallery and News & Event). Without these
                        cookies, you will not be able to use our site as expected. These are cookies deposited by DBIMA which concern the optimal functioning of our site.
                    </p>

                    <p>
                        We also inform you that to provide you with a secure service in accordance with your expectations,DBIMA collects information as part of the fight against fraud to secure authentication to our site. This is information relating to your terminal, in particular
                        technical attributes necessary for the recognition of equipment (computer, tablet or mobile phone).
                    </p>

                    <p>
                        You can always express your choice here to oppose the collection of your terminal identifiers (computer, tablet) for the security.
                    </p>


                    <h3>Analytical cookies, necessary for audience measurement</h3>
                    <p>
                        These are cookies that allow us to know the use, the volumes of visits and use as well as the performance of our site. These cookies allow <b>DBIMA</b> to improve the interest, ergonomics and operation of the services offered on
                        the site (for example, the pages most often consulted, searches by Internet users in the site engine, etc.). DBIMA uses Statistics with Google Analytics.

                    </p>


                    <h3>Functional cookies</h3>
                    <p>These are cookies that allow us to provide you with certain features that are not essential to the service but that allow you to significantly improve your experience, such as the adaptation of certain parts of our site to your journey
                        or the proposal of tools adapted to your use.</p>
                    <p>We may deposit a geolocation cookie on the site, in order to locate you to allow you to benefit from certain functionalities. Geolocation cookies are kept for a period limited to the needs of the service.
                    </p>

                    <h3>Recognition of your devices</h3>
                    <p>We use certain cookies and share non-directly identifying information to link together the different terminals and/or environments (computers, mobile phones, etc.) from which you consult the site in order to offer you a consistent
                        and harmonized experience whatever how you navigate.
                    </p>


                    <h3>Cookies and social media buttons</h3>
                    <p>These cookies and social network buttons are intended to allow users to use the functionalities of the various platforms and in particular to share pages and content via these third-party social networks. They also allow social networks
                        to target the advertising offer they broadcast on their platforms.</p>
                    <p>For more information on the use of your personal data in relation to social networks, and since social network cookies are managed by the publisher of the social network site, we invite you to consult the confidentiality policies of
                        the networks. concerned third-party social workers.</p>
                    <ul>
                        <li><a href="https://www.facebook.com/dbimaeu" target="_blank">Facebook</a></li>
                        <li><a href="https://twitter.com/DBIMA_EU" target="_blank">Twitter</a></li>
                        <li><a href="https://www.linkedin.com/company/dbimaeu/" target="_blank">LinkedIn</a></li>
                        <li><a href="https://www.instagram.com/dbima_france/" target="_blank">Instagram</a></li>
                        <li><a href="https://www.youtube.com/channel/UC9rc0LuQA7FYBEOHivM1dIA/featured " target="_blank">Youtube</a></li>
                    </ul>

                    <h1>Collecting and Using Your Personal Data</h1>
                    <h3>Types of Data Collected</h3>
                    <h2>1) Personal Data</h2>
                    <p>While using Our Service, DBIMA may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally, identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Usage Data</li>
                    </ul>

                    <h2>2) Usage Data</h2>
                    <p>Usage Data has collected automatically when using the Service.</p>
                    <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those
                        pages, unique device identifiers and other diagnostic data.</p>
                    <p>When You access the Service by or through a mobile device, DBIMA may collect certain information automatically, including, but not limited to, the type of mobile device You use, your mobile device unique ID, the IP address of Your
                        mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                    <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>


                    <h3>Retention of Your Personal Data</h3>
                    <p>DBIMA will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example,
                        if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p>DBIMA will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service,
                        or We are legally obligated to retain this data for longer time periods.</p>

                    <h3>Transfer of Your Personal Data</h3>
                    <p>Your information, including Personal Data, is processed at the DBIMA’s operating offices and in any other places where DBIMA involved in the processing are located. It means that this information may be transferred to — and maintained
                        on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                    <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p>DBIMA will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there
                        are adequate controls in place including the security of Your data and other personal information.</p>

                    <h3>Security of Your Personal Data</h3>
                    <p>The security of Your Personal Data is important to Us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your
                        Personal Data, we cannot guarantee its absolute security.
                    </p>

                    <h3>Children's Privacy</h3>
                    <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided
                        Us with Personal Data, please Contact Us. If DBIMA become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, DBIMA take steps to remove that information from Our
                        servers.
                    </p>
                    <p>If DBIMA need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

                    <h3>Links to Other Websites</h3>
                    <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You
                        visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                    <h3>Changes to this Privacy Policy</h3>
                    <p>DBIMA may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last Updated" date at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                    <h3>Contact Us</h3>
                    <p>If you have any questions about this Cookie & Privacy Policy, You can contact us:<br> By email: <b><a href="mailto:support@dbi-ecademy.fr">support@dbi-ecademy.fr</a></b>.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li class="active"><a routerLink="/cookie-and-privacy-policy">Cookie & Privacy Policy</a></li>
                            <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                        </ul>
                    </section>
                    <app-widget-recent-programs></app-widget-recent-programs>
                </aside>
            </div>
        </div>
    </div>
</div>