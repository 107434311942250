<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/programs">Our Programs</a>
                </li>
                <li>{{program_name}}</li>
            </ul>
            <h2 class="cs-display-middle">{{program_name}}</h2>
        </div>
        <img src="assets/img/site/illustrations/graduation.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>



<section class="courses-details-area">

    <div class="container mb-3">
        <div class="row">
            <div class="col-lg-8 col-md-12">

                <div class="products-details-tabs">
                    <ngx-tabset>

                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <div class="courses-details-image-style-two text-center">
                                    <img src="assets/img/site/programs/p3/p.jpg" alt="{{programDetails.p_alt}}">
                                </div>
                                <div class="courses-details-desc-style-two">
                                    <h3>Description:</h3>
                                    <p>The main goal of our master program is to develop a personal vision for our students through a professional application of the student's end of year project.</p>
                                    <p>A specialization is mandatory for the 2nd year of master. The offered specializations are Cinematography, Directing, Postproduction, and Sound Postproduction.</p>
                                    <p>For example, it is mandatory for a master student to specialize in scriptwriting in their 2nd year to write a feature film project, starting from synopsis to development.</p>
                                    <p>This personal project, developed through one-on-one tutoring, can become the main professional project of the student.</p>

                                    <!-- <hr> -->
                                    <!-- <p>In the same way, it is mandatory for a master student, specializing in
                                        Cinematography to handle the responsibilities as Director of Photography work on
                                        a thesis project as the bachelor program, the master program is
                                        including: </p>
                                    <ul class="description-features-list ">
                                        <li>Masterclasses are organized every month for students to meet advanced
                                            professionals and discover their careers, their choices, experience, as well
                                            as their actual work </li>
                                        <li>Practical classes are held twice a week at a minimum, from camera training,
                                            lighting courses, to sound-on-set courses as well as editing, color grading
                                            and sound mixing courses (Master 1st year) </li>
                                        <li>Each year, the last semester is a semester of the shoot known as a
                                            production period</li>
                                        <li>During their studies students are asked to complete and present several
                                            projects or finished works</li>
                                        <li>Grading considers presence, involvement, practice, and theory
                                        </li>
                                        <li>An elective option is mandatory in the 3rd year, thus creating a personal
                                        </li>
                                        <li>Schedule of studies with the mandatory achievement on DBIMA’s movies. </li>
                                        <li>By the end of a student’s studies, they will have a personal show-reel to
                                            represent their work</li>
                                        <li>Linked to Paris and French art and culture, special courses can be held in
                                            Museums and Exhibitions </li>
                                    </ul> -->
                                    <!-- <br> -->
                                    <h3>Here are noticeable points of our Master program: </h3>
                                    <ul class="description-features-list ">
                                        <li><strong>Masterclasses are organized regularly for students to meet advanced professionals and discover their careers, their choices, experience, as well as their actual work. </strong></li>
                                        <li><strong>Practical classes are held twice a week on camera training, lighting courses, sound-on-set courses as well as editing, colour grading, and sound mixing courses throughout the year. </strong></li>
                                        <li><strong>Each year, the last three months are dedicated to shooting the students short films. </strong></li>
                                        <li><strong>During their studies, the students are asked to complete and present in several projects. </strong></li>
                                        <li><strong>The final grading considers presence, involvement, practice, and theory. </strong></li>
                                        <li><strong>By the end of their studies, they will have a personal show-reel to represent their work. </strong></li>
                                        <li><strong>One-on-one tutoring for all personal student's end of year projects. </strong></li>
                                        <li><strong>Pitching courses and Pitching training/mentoring. </strong></li>
                                        <li><strong>Workshops during 2nd semester. </strong></li>
                                        <li><strong>Mandatory Thesis Defence. </strong></li>
                                        <li><strong>Mentoring during semester of production. </strong></li>
                                    </ul>


                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Curriculum">

                            <div class="">
                                <div class="container courses-curriculum-contain" style="margin-left: 0rem;">
                                    <!-- <hr class="cu-hr"> -->
                                    <div class="faq-accordion">
                                        <accordion>
                                            <accordion-group heading="Year 1">
                                                <ul>
                                                    <li><strong>Film Directing I & II </strong></li>
                                                    <li><strong>Editing Techniques & Languages I & II </strong></li>
                                                    <li><strong>Camera Lighting I & II </strong></li>
                                                    <li><strong>Producer Work & Task </strong></li>
                                                    <li><strong>Scriptwriting for Film & Series I & II </strong></li>
                                                    <li><strong>Actors Coordination I </strong></li>
                                                    <li><strong>Thesis Part I - Methodology & Prospectus </strong></li>
                                                    <li><strong>Foundation Course </strong></li>
                                                    <li><strong>English Language </strong></li>
                                                    <li><strong>History of Cinema I & II </strong></li>
                                                    <li><strong>Sound Post Production I & II </strong></li>
                                                    <li><strong>Film Analysis and Critique </strong></li>
                                                    <li><strong>Film Project Management </strong></li>
                                                    <li><strong>French Language </strong></li>
                                                    <li><strong>Final Project I </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                </ul>
                                            </accordion-group>
                                            <accordion-group heading="Year 2">
                                                <ul>
                                                    <li><strong>Directing Sessions </strong></li>
                                                    <li><strong>Producer Writing Guidance </strong></li>
                                                    <li><strong>Advanced Acting Direction </strong></li>
                                                    <li><strong>Film Financing </strong></li>
                                                    <li><strong>Editing & Storytelling </strong></li>
                                                    <li><strong>Thesis Part II - Writing & Defense </strong></li>
                                                    <li><strong>Marketing & Pitching </strong></li>
                                                    <li><strong>The Art of Cinematography </strong></li>
                                                    <li><strong>Sound Design for Filmmaking </strong></li>
                                                    <li><strong>English Language </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                    <li><strong>Final Project Master II </strong></li>
                                                    <li><strong>Internship (Production Period) </strong></li>
                                                </ul>
                                            </accordion-group>
                                        </accordion>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Requirements">
                            <div class="courses-requirements">

                                <div class="courses-details-desc-style-two">

                                    <h3>1st Year Admission Master of Arts in Cinema and Audiovisual</h3>
                                    <ul class="description-features-list ">
                                        <li>Bachelor’s degree or Equivalent (licentiate, Under Graduation Diploma, etc.) - A copy of diploma should be produced. </li>
                                        <li>Equivalence in Credits (180 Credits ECTS) of three years of studies in Cinema. </li>
                                        <li>Audiovisual, Mass Media and Communication by Arts School - Academic reports for 3 years of study should be produced. </li>
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees (once selected). </li>
                                    </ul>

                                    <h3>Direct entrance in 2nd year of Master of Arts in Cinema and Audiovisual</h3>
                                    <ul class="description-features-list ">
                                        <li>Bachelor’s degree or Equivalent (licentiate, Under Graduation Diploma, etc.) -A copy of diploma should be produced + one-year studies. </li>
                                        <li>Post-Graduation Diploma or Equivalent - a copy of diploma should be produced. </li>
                                        <li>Equivalence in Credits (60 Credits ECTS) of 1 year of post-Bachelor studies in Cinema, Audiovisual, Mass Media and Communication by Arts School - academic reports for 1 years of study should be produced. </li>
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees once selected. </li>
                                        
                                    </ul>

                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 " style="padding-top: 100px;">
                <div class="courses-sidebar-information">
                    <ul class="info ">
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-time "></i> Duration</span> 2 Years
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-distance-learning "></i> Subjects</span> 35
                            </div>
                        </li> -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-brain-process"></i> Credits</span> 120 ECTS
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-html "></i> Language</span> English
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-certification "></i> Certificate</span> European Degree
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box ">
                        <a routerLink="/register-for/{{program_id}}/{{program_url}}" class="default-btn ">Apply Now<i
                                class="flaticon-verify ml-2"></i><span></span></a>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pt-100 pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>More Courses You Might Like</h2>
        </div>

        <div class="row ">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program1-mini></app-program1-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program2-mini></app-program2-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program4-mini></app-program4-mini>
                </div>
            </div>
        </div>

    </div>
</section>