import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { getPartnerDetails } from 'src/app/models/endpoints';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var setInnerHtml;
declare var viewFullImg;

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {
    url_data: any;
    partner_id: any;

    partnerData: any;
    partnerDataCount = 0;
    base_logo_URl = 'https://admin.dbima.eu/app/assets/img/uploads/ps-partners/logo/';
    base_content_URl = 'https://admin.dbima.eu/app/assets/img/uploads/ps-partners/content/';

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.partner_id = this.url_data.partner_id;
        this.loadData();
    }

    loadData() {
        this.http.get<any>(environment.baseUrl + getPartnerDetails + this.partner_id).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    var dataList = data.data;

                    if (dataList.length != 0) {
                        this.noData = false;
                    }
                    this.partnerData = dataList[0];
                    this.partnerDataCount = this.partnerData.length;
                    this.loading = false;

                    setTimeout(() => {
                        this.callTo_setInnerHtml('content_html_div',dataList[0].content_html);
                    }, 100);

                } else {
                    this.loading = false;
                }
            },
            error: (error) => {
                this.openSnackBar('Oops, Something went wrong. please try again later. ' , 1);
                console.log(error.message);
            },
        });
    }

    callTo_setInnerHtml(id,content){
        setInnerHtml(id,content);
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    callTo_viewFullImg(src){
        viewFullImg(src);
    }

}
