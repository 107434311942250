<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Download Resources</li>
            </ul>
            <h2>Download Resources</h2>
        </div>
        <img src="assets/img/site/illustrations/cloud_files.svg" alt="" style="height: 190px; width: auto" class="cs-display-bottomright pt-10 cs-hide-small" />
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px; width: auto" class="cs-display-bottomleft pt-10 cs-hide-small" />
    </div>

    <div class="shape9">
        <img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon" />
    </div>
</div>

<section class="courses-area pb-100">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let flyer of flyerDetails;">
                        <div class=" single-courses-box" style="height: 90%;">
                            <div class="courses-image">
                                <a href="{{baseFileUrl + flyer.file_name}}" target="_blank" class="d-block image">
                                    <img src="{{flyer.thumbnail}}" alt="image" style="width: 750px;height: 250px;object-fit: cover;" onerror="onErrChangeSrc(this)">
                                </a>
                                <a href="{{baseFileUrl + flyer.file_name}}" target="_blank">
                                    <div class="btn-download shadow"><i class="bx bx-download"></i></div>
                                </a>
                            </div>

                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center mb-1" style="line-height: 1;">
                                    <span>{{flyer.type}}</span>
                                </div>
                                <h3><a href="{{baseFileUrl + flyer.file_name}}" target="_blank">{{flyer.name}}</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>