<div *ngIf="loading">
    <app-loader></app-loader>
</div>
<div *ngIf="!loading">
    <div *ngIf="!noData">
        <div class="page-title-area">
            <div class="container cs-display-container" style="height: 200px">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/partners">Partners</a></li>
                        <li>{{partnerData.name}}</li>
                    </ul>
                    <div class="row cs-hide-large">
                        <div class="col-12" align="center">
                            <img src="{{ base_logo_URl }}{{ partnerData.logo_img_name }}" (click)="callTo_viewFullImg(base_logo_URl + partnerData.logo_img_name)" onerror="onErrChangeSrc(this)" alt="" style="height: 100px; width: auto;" class="pt-10 cu-pointer mt-2" />
                        </div>
                    </div>
                    <h2>{{partnerData.name}}</h2>
                </div>
                <img src="{{ base_logo_URl }}{{ partnerData.logo_img_name }}" (click)="callTo_viewFullImg(base_logo_URl + partnerData.logo_img_name)" onerror="onErrChangeSrc(this)" alt="" style="height: 100px; width: auto;" class="cs-display-bottomright cs-hide-small cs-hide-medium pt-10 cu-pointer"
                />
                <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small cs-hide-medium">
            </div>
            <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
        </div>

        <section class="apply-instructor-area pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="apply-instructor-image">
                            <img src="{{ base_content_URl }}{{ partnerData.content_img_name }}" (click)="callTo_viewFullImg(base_content_URl + partnerData.content_img_name)" onerror="onErrChangeSrc(this)" alt="image" class=" cu-pointer">
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="apply-instructor-content">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" role="tabpanel">
                                    <div id="content_html_div" class="content_html_div">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div *ngIf="noData">
        <app-data-not-found></app-data-not-found>
    </div>
</div>
