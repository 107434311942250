import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';
import { languages, country_code } from 'src/app/models/data';
import { ActivatedRoute } from '@angular/router';

declare var setSelectedCourse, $, setDefaultSelect, submitRegForm;
@Component({
  selector: 'app-register-now',
  templateUrl: './register-now.component.html',
  styleUrls: ['./register-now.component.scss']
})
export class RegisterNowComponent implements OnInit {
    url_data: any;
    today: any;

    programDetails = details;
    languageList = languages;
    countryCodeList = country_code;
    str_programDetails = JSON.stringify(details);
    applied = false;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.getDateTime();
        this.loadData();
        this.setFormStyles();
    }

    setFormStyles() {
        document.getElementById('reg_sec_1_form').removeAttribute("novalidate");
        document.getElementById('reg_sec_2_form').removeAttribute("novalidate");
        document.getElementById('reg_sec_3_form').removeAttribute("novalidate");
        setTimeout(() => {
            this.callTo_setDefaultSelect();
        }, 1000);
    }

    callTo_submitRegForm(id: any) {
        submitRegForm(id);
    }

    loadData() {
        var program_id = this.url_data.program_id;
        if (program_id != null) {
            setTimeout(() => {
                this.callToSetSelectedCourse(program_id);
                this.applied = true;
            }, 500);
        } else {
            this.applied = false;
        }
    }

    getDateTime = () => {
        const date = new Date();
        this.convertTZ(date, 'Asia/Colombo');
        this.today =
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getDate();

        this.today = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(this.today));

        var str_year = this.today.substring(6);
        var str_month = this.today.substring(0, 2);
        var str_date = this.today.substring(3, 5);

        this.today = str_year + '-' + str_month + '-' + str_date;
    };

    convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === 'string' ? new Date(date) : date).toLocaleString(
                'en-US',
                { timeZone: tzString }
            )
        );
    };

    callTo_setDefaultSelect() {
        setDefaultSelect();
    }

    callToSetSelectedCourse(id) {
        setSelectedCourse(id);
    }
}
