import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
    selector: 'app-home-programs',
    templateUrl: './home-programs.component.html',
    styleUrls: ['./home-programs.component.scss']
})
export class HomeProgramsComponent implements OnInit {

    programDetails = details;

    constructor() { }

    ngOnInit(): void {
    }

    lengthFix(description: string, length: any) {
        var finalStr = stringCut;
        var strLength = description.length;
        /*$string = strip_tags($loadDataRow["cm_message"]);
        if (strlen($string) > 30) {
            // truncate string
            $stringCut = substr($string, 0, 30);
            $endPoint = strrpos($stringCut, ' ');
            //if the string doesn't contain any space then it will cut without word basis.
            $string = $endPoint ? substr($stringCut, 0, $endPoint) : substr($stringCut, 0);
            $string .= '...';
        }*/

        if (strLength > length) {
            var stringCut = description.substring(0, length);
            var endPoint = stringCut.lastIndexOf(' ');
            var string = stringCut.substring(0, endPoint) + "...";
            finalStr = string;
        } else {
            finalStr = description;
        }

        return finalStr;
    }
}
