import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
  selector: 'app-widget-recent-programs',
  templateUrl: './widget-recent-programs.component.html',
  styleUrls: ['./widget-recent-programs.component.scss']
})
export class WidgetRecentProgramsComponent implements OnInit {
  programDetails = details;
  constructor() { }

  ngOnInit(): void {
  }

}
