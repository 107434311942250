import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
    selector: 'app-program2',
    templateUrl: './program2.component.html',
    styleUrls: ['./program2.component.scss']
})
export class Program2Component implements OnInit {

    programDetails = details[1];

    program_id = this.programDetails.id;
    program_name = this.programDetails.name;
    program_url = this.programDetails.url;

    constructor() {}

    ngOnInit(): void {}
}
