import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getHomeBanner } from 'src/app/models/endpoints';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.callTo_getBanner();
  }

  load=0
  bannerImgdata: any=[];
  baseImgUrl = "https://admin.dbima.eu/app/assets/img/uploads/ps-slider/";

  noData = true;
  loading = true;


  bgImage = [
    {
        img: 'assets/img/site/home_banner/xxxxxxxxx.jpg'
    }
]

slides: OwlOptions = {
  nav: false,
  margin: 30,
  loop: true,
  dots: false,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: false,
  navText: [
      "<i class='flaticon-chevron'></i>",
      "<i class='flaticon-right-arrow'></i>"
  ],
  items:1,
  autoplaySpeed:2500,
  autoWidth: true
}

callTo_getBanner() {
  this.http.get<any>(environment.baseUrl + getHomeBanner).subscribe({
      next: (data) => {
          if (data.status == 1) {
              var dataList = data.data;

              if (dataList.length != 0) {
                  this.noData = false;
              }
              this.bannerImgdata = dataList;
              this.loading = false;
              setTimeout(() => {
                this.load=1
              }, 20);
          } else {
              this.loading = false;
          }          
      },
      error: (error) => {
          // this.noData = true;
          // this.loading = false;
          // this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
          console.log(error.message);
      },
  });
}

openSnackBar(message, state) {
  if (state == 1) {
      this.snackBar.open(message, 'Dismiss', { duration: 5000 });
  } else if (state == 0) {
      this.snackBar.open(message, 'Dismiss');
  }
}

}
