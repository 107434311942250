<section class="about-area-two ptb-70" style="background-color: #eeeeee!important;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title cs-hide">Distance Learning</span>
                    <h2>Why DBIMA ?</h2>
                    <p>Crafted by industry professionals and expert professors, 
                        DBIMA courses uniquely prepare students for the evolving creative cinema economy. 
                        In friendly classrooms, students benefit from personalized instruction and individual 
                        computer workstations featuring industry-standard software.
                    <p>Experience elite industry technology, setting us apart and providing hands-on advantages.
                        Expand knowledge and opportunities with cutting-edge training and tools at DBIMA.
                    </p>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a data-lg-size="1280-720" data-src= "https://www.youtube.com/watch?v=OO9PH36QAYI"
                            data-sub-html="<p>Why DBIMA ?</p>" class="d-block">
                            <div class="image">
                                <img src="assets/img/site/home/why-dbima.jpg" alt="image">
                            </div>
                            <div class="video-btn">
                                <i class="flaticon-play"></i>
                            </div>
                        </a>
                    </lightgallery>
                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="row reorder align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a data-lg-size="1280-720" data-src="https://www.youtube.com/watch?v=-lvt_wULNe8"
                            data-sub-html="<p>Campus life</p>" class="d-block">
                            <div class="image">
                                <img src="assets/img/site/home/campus-life.jpg" alt="image">
                            </div>
                            <div class="video-btn">
                                <i class="flaticon-play"></i>
                            </div>
                        </a>
                    </lightgallery>
                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title cs-hide">Distance Learning</span>
                    <h2>Campus life</h2>
                    <p>Nestled in Paris’ vibrant, creative neighborhood, 
                        echoing with French legends like Maurice Chevalier and Édith Piaf, 
                        our students immerse themselves in the city’s cultural tapestry. 
                        Studying collectively, they explore renowned museums and galleries, 
                        ensuring a memorable and rewarding Parisian experience.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="assets/img/site/shapes/lens.png"
            alt="pink lense icon" style="height: 300px;"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="assets/img/shape4.png" alt="two pink rings icon">
    </div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="assets/img/shape2.png"
            alt="Angled two black lines and one pink line"></div>
</section>