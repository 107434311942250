import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { getAllProfessors } from 'src/app/models/endpoints';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var viewFullImg;
@Component({
    selector: 'app-professors',
    templateUrl: './professors.component.html',
    styleUrls: ['./professors.component.scss']
})
export class ProfessorsComponent implements OnInit {

    align = ['right', 'left'];
    professorsDetails: any;
    baseImgUrl = "https://admin.dbima.eu/app/assets/img/uploads/ps-professors/";

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
        this.callTo_getProfessors();
    }

    callTo_getProfessors() {
        this.http.get<any>(environment.baseUrl + getAllProfessors).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    var dataList = data.data;

                    if (dataList.length != 0) {
                        this.noData = false;
                    }
                    this.professorsDetails = dataList;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            },
            error: (error) => {
                this.noData = true;
                this.loading = false;
                this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
                console.log(error.message);
            },
        });
    }

    callTo_viewFullImg(src) {
        viewFullImg(src);
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }
}
