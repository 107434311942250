import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
    selector: 'app-program4-mini',
    templateUrl: './program4-mini.component.html',
    styleUrls: ['./program4-mini.component.scss']
})
export class Program4MiniComponent implements OnInit {

    programDetails = details[3];

    constructor() { }

    ngOnInit(): void {
    }

}
