import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/pages/home/home.component';
import { ErrorComponent } from './components/pages/error/error.component';

import { NewsAndEventsComponent } from './components/pages/news-and-events/news-and-events.component';
import { NewsAndEventsDetailsComponent } from './components/pages/news-and-events/news-and-events-details/news-and-events-details.component';

import { ProgramsComponent } from './components/pages/programs/programs.component';
import { MiniProgramViewComponent } from './components/pages/programs/mini-program-view/mini-program-view.component';

import { Program1Component } from './components/pages/programs/program1/program1.component';
import { Program2Component } from './components/pages/programs/program2/program2.component';
import { Program3Component } from './components/pages/programs/program3/program3.component';
import { Program4Component } from './components/pages/programs/program4/program4.component';

import { DownloadResourcesComponent } from './components/pages/programs/download-resources/download-resources.component';
import { FaqComponent } from './components/pages/programs/faq/faq.component';

import { GalleryComponent } from './components/pages/our-faculty/gallery/gallery.component';
import { AboutUsComponent } from './components/pages/our-faculty/about-us/about-us.component';
import { ProfessorsComponent } from './components/pages/our-faculty/professors/professors.component';
import { AimsAndEthosComponent } from './components/pages/our-faculty/aims-and-ethos/aims-and-ethos.component';
import { HistoryComponent } from './components/pages/our-faculty/history/history.component';
import { PartnersComponent } from './components/pages/our-faculty/partners/partners.component';
import { PartnerDetailsComponent } from './components/pages/our-faculty/partners/partner-details/partner-details.component';

import { ContactUsComponent } from './components/pages/our-faculty/contact-us/contact-us.component';

import { RegisterNowComponent } from './components/pages/register-now/register-now.component';

import { AcademicPortalComponent } from './components/pages/academic-portal/academic-portal.component';

import { TermsAndConditionsComponent } from './components/pages/company/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/company/privacy-policy/privacy-policy.component';


// import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';
// import { VendorCertificationEtrainingComponent } from './components/pages/vendor-certification-etraining/vendor-certification-etraining.component';
// import { OnlineTrainingSchoolComponent } from './components/pages/online-training-school/online-training-school.component';
// import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
// import { LanguageSchoolComponent } from './components/pages/language-school/language-school.component';
// import { ModernSchoolingComponent } from './components/pages/modern-schooling/modern-schooling.component';
// import { YogaTrainingComponent } from './components/pages/yoga-training/yoga-training.component';
// import { HealthCoachingComponent } from './components/pages/health-coaching/health-coaching.component';
// import { KindergartenComponent } from './components/pages/kindergarten/kindergarten.component';
// import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
// import { GalleryComponent } from './components/pages/gallery/gallery.component';
// import { AboutStyleOneComponent } from './components/pages/about-style-one/about-style-one.component';
// import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
// import { AboutStyleThreeComponent } from './components/pages/about-style-three/about-style-three.component';
// import { AboutStyleFourComponent } from './components/pages/about-style-four/about-style-four.component';
// import { SuccessStoryComponent } from './components/pages/success-story/success-story.component';
// import { TeacherComponent } from './components/pages/teacher/teacher.component';
// import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component';
// import { ErrorComponent } from './components/pages/error/error.component';
// import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
// import { PurchaseGuideComponent } from './components/pages/purchase-guide/purchase-guide.component';
// import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
// import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
// import { FaqComponent } from './components/pages/faq/faq.component';
// import { CoursesStyleOneComponent } from './components/pages/courses-style-one/courses-style-one.component';
// import { CoursesStyleTwoComponent } from './components/pages/courses-style-two/courses-style-two.component';
// import { CoursesStyleThreeComponent } from './components/pages/courses-style-three/courses-style-three.component';
// import { CoursesStyleFourComponent } from './components/pages/courses-style-four/courses-style-four.component';
// import { CoursesStyleFiveComponent } from './components/pages/courses-style-five/courses-style-five.component';
// import { CoursesStyleSixComponent } from './components/pages/courses-style-six/courses-style-six.component';
// import { CoursesStyleSevenComponent } from './components/pages/courses-style-seven/courses-style-seven.component';
// import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component';
// import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component';
// import { ProfileComponent } from './components/pages/profile/profile.component';
// import { ProfileQuizComponent } from './components/pages/profile-quiz/profile-quiz.component';
// import { MembershipLevelsComponent } from './components/pages/membership-levels/membership-levels.component';
// import { BecomeATeacherComponent } from './components/pages/become-a-teacher/become-a-teacher.component';
// import { CategoriesComponent } from './components/pages/categories/categories.component';
// import { EventsComponent } from './components/pages/events/events.component';
// import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
// import { ProductsListStyleOneComponent } from './components/pages/products-list-style-one/products-list-style-one.component';
// import { ProductsListStyleTwoComponent } from './components/pages/products-list-style-two/products-list-style-two.component';
// import { CartComponent } from './components/pages/cart/cart.component';
// import { CheckoutComponent } from './components/pages/checkout/checkout.component';
// import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
// import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component';
// import { BlogStyleTwoComponent } from './components/pages/blog-style-two/blog-style-two.component';
// import { BlogStyleThreeComponent } from './components/pages/blog-style-three/blog-style-three.component';
// import { BlogStyleFourComponent } from './components/pages/blog-style-four/blog-style-four.component';
// import { BlogStyleFiveComponent } from './components/pages/blog-style-five/blog-style-five.component';
// import { BlogDetailsStyleOneComponent } from './components/pages/blog-details-style-one/blog-details-style-one.component';
// import { BlogDetailsStyleTwoComponent } from './components/pages/blog-details-style-two/blog-details-style-two.component';
// import { BlogDetailsStyleThreeComponent } from './components/pages/blog-details-style-three/blog-details-style-three.component';
// import { GymCoachingComponent } from './components/pages/gym-coaching/gym-coaching.component';
// import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';
// import { BusinessCoachingComponent } from './components/pages/business-coaching/business-coaching.component';
// import { MotivationalCoachingComponent } from './components/pages/motivational-coaching/motivational-coaching.component';
// import { KitchenCoachingComponent } from './components/pages/kitchen-coaching/kitchen-coaching.component';

const routes: Routes = [
    { path: '', component: HomeComponent },

    { path: 'news-and-events', component: NewsAndEventsComponent },
    { path: 'news-and-events-details/:news_id/:news_title', component: NewsAndEventsDetailsComponent },

    { path: 'programs', component: ProgramsComponent },
    { path: 'mini-program/:mini_id', component: MiniProgramViewComponent },

    { path: 'bts-en-alternance', component: Program1Component },
    { path: 'bachelor-of-arts-in-cinema-and-audiovisual', component: Program2Component },
    { path: 'master-of-arts-in-cinema-and-audiovisual', component: Program3Component },
    { path: 'one-year-film-program', component: Program4Component },

    { path: 'download-resources', component: DownloadResourcesComponent },

    { path: 'faq', component: FaqComponent },
    { path: 'faq/:lang_code', component: FaqComponent },

    { path: 'about-us', component: AboutUsComponent },
    { path: 'professors', component: ProfessorsComponent },
    { path: 'aims-and-ethos', component: AimsAndEthosComponent },
    { path: 'history', component: HistoryComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'partners', component: PartnersComponent },
    { path: 'partner-details/:partner_id/:partner_name', component: PartnerDetailsComponent },

    { path: 'contact-us', component: ContactUsComponent },

    { path: 'register-now', component: RegisterNowComponent },
    { path: 'register-for/:program_id/:program_name', component: RegisterNowComponent },

    { path: 'academic-portal', component: AcademicPortalComponent },

    { path: 'cookie-and-privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },

    { path: '**', component: ErrorComponent },

    // {path: 'vendor-certification-etraining', component: VendorCertificationEtrainingComponent},
    // {path: 'online-training-school', component: OnlineTrainingSchoolComponent},
    // {path: 'distance-learning', component: DistanceLearningComponent},
    // {path: 'language-school', component: LanguageSchoolComponent},
    // {path: 'modern-schooling', component: ModernSchoolingComponent},
    // {path: 'yoga-training', component: YogaTrainingComponent},
    // {path: 'health-coaching', component: HealthCoachingComponent},
    // {path: 'kindergarten', component: KindergartenComponent},
    // {path: 'gym-coaching', component: GymCoachingComponent},
    // {path: 'learning-management', component: LearningManagementComponent},
    // {path: 'business-coaching', component: BusinessCoachingComponent},
    // {path: 'motivational-coaching', component: MotivationalCoachingComponent},
    // {path: 'kitchen-coaching', component: KitchenCoachingComponent},
    // {path: 'about-1', component: AboutStyleOneComponent},
    // {path: 'about-2', component: AboutStyleTwoComponent},
    // {path: 'about-3', component: AboutStyleThreeComponent},
    // {path: 'about-4', component: AboutStyleFourComponent},
    // {path: 'success-story', component: SuccessStoryComponent},
    // {path: 'teacher', component: TeacherComponent},
    // {path: 'profile-authentication', component: ProfileAuthenticationComponent},
    // {path: 'faq', component: FaqComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    // {path: 'purchase-guide', component: PurchaseGuideComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    // {path: 'terms-of-service', component: TermsOfServiceComponent},
    // {path: 'courses-1', component: CoursesStyleOneComponent},
    // {path: 'courses-2', component: CoursesStyleTwoComponent},
    // {path: 'courses-3', component: CoursesStyleThreeComponent},
    // {path: 'courses-4', component: CoursesStyleFourComponent},
    // {path: 'courses-5', component: CoursesStyleFiveComponent},
    // {path: 'courses-6', component: CoursesStyleSixComponent},
    // {path: 'courses-7', component: CoursesStyleSevenComponent},
    // {path: 'single-courses-1', component: CoursesDetailsStyleOneComponent},
    // {path: 'single-courses-2', component: CoursesDetailsStyleTwoComponent},
    // {path: 'profile', component: ProfileComponent},
    // {path: 'profile-quiz', component: ProfileQuizComponent},
    // {path: 'membership-levels', component: MembershipLevelsComponent},
    // {path: 'become-a-teacher', component: BecomeATeacherComponent},
    // {path: 'categories', component: CategoriesComponent},
    // {path: 'events', component: EventsComponent},
    // {path: 'single-events', component: EventsDetailsComponent},
    // {path: 'products-list-1', component: ProductsListStyleOneComponent},
    // {path: 'products-list-2', component: ProductsListStyleTwoComponent},
    // {path: 'cart', component: CartComponent},
    // {path: 'checkout', component: CheckoutComponent},
    // {path: 'single-products', component: ProductsDetailsComponent},
    // { path: 'gallery', component: GalleryComponent },
    // {path: 'blog-1', component: BlogStyleOneComponent},
    // {path: 'blog-2', component: BlogStyleTwoComponent},
    // {path: 'blog-3', component: BlogStyleThreeComponent},
    // {path: 'blog-4', component: BlogStyleFourComponent},
    // {path: 'blog-5', component: BlogStyleFiveComponent},
    // {path: 'single-blog-1', component: BlogDetailsStyleOneComponent},
    // {path: 'single-blog-2', component: BlogDetailsStyleTwoComponent},
    // {path: 'single-blog-3', component: BlogDetailsStyleThreeComponent},
    // {path: 'contact', component: ContactUsComponent},
    // // Here add new pages component


];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
