<div *ngIf="loading">
    <app-loader></app-loader>
</div>
<div [ngClass]="{'cs-hide' : loading}">
    <div [ngClass]="{'cs-hide' : noData}">
        <div class="page-title-area">
            <div class="container cs-display-container" style="height: 200px;">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/news-and-events">News & Events</a></li>
                        <li>{{newsEventData.title}}</li>
                    </ul>
                    <h2>{{newsEventData.title}}</h2>

                </div>
                <img src="assets/img/site/illustrations/online_articles.svg" alt="" style="height: 180px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
                <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
            </div>

            <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
        </div>
        <div class="events-details-area pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-md-12 m-auto">

                        <div class="cs-padding">
                            <div class="row">
                                <img [ngClass]="{'cs-hide' : newsEventData.imageUrl == '' && newsEventData.videoUrl != ''}" class="m-auto" style="width: 100%;height: auto;border-radius: 2%;" src="https://admin.dbima.eu/app/assets/img/uploads/ps-news-events/{{newsEventData.imageUrl}}"
                                    onerror="onErrChangeSrc(this)" alt="image">
                            </div>

                            <div [ngClass]="{'cs-hide' : newsEventData.imageUrl != '' && newsEventData.videoUrl == ''}">

                                <div class="about-video-box" style="margin-left:0px;">
                                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                                        <a data-lg-size="1280-720" [attr.data-src]="newsEventData.trueVideoUrl" [attr.data-sub-html]="newsEventData.title" class="d-block">
                                            <div class="image">
                                                <img src="https://img.youtube.com/vi/{{newsEventData.videoId}}/0.jpg" alt="Video Thumbnail" class="newsVideo">
                                            </div>
                                            <div class="video-btn">
                                                <i class="flaticon-play"></i>
                                            </div>
                                        </a>
                                    </lightgallery>
                                </div>
                            </div>

                        </div>

                        <div class="events-details-header cs-padding mb-0 mt-5" *ngIf="newsEventData.type == 'event'">
                            <ul style="width: fit-content;" class="cs-border cs-round-large cs-white">
                                <li><i class="bx bx-calendar"></i>{{fixDate(newsEventData.dateTime)}}</li>
                                <li><i class="bx bx-map"></i>{{newsEventData.location}}</li>
                                <li><i class="bx bx-time"></i>{{setTime(newsEventData.dateTime)}}</li>
                            </ul>
                        </div>

                        <div class="cs-padding-64">
                            <div class="events-details-desc" id="content_html_div"></div>

                            <div class="products-details-desc">
                                <div class="products-meta">
                                    <span class="date cs-tiny cs-right">{{fixDate(newsEventData.createdAt)}}</span>
                                    <br>
                                    <span>
                                        <h2 class="text-capitalize cs-text-gray cs-tiny cs-right">
                                            <span *ngIf="newsEventData.type == 'event' ">{{newsEventData.status}} {{newsEventData.type}}</span>
                                    <span *ngIf="newsEventData.type == 'news' ">{{newsEventData.type}}</span>
                                    </h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="noData">
        <div class="page-title-area">
            <div class="container cs-display-container" style="height: 200px;">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/news-and-events">News & Events</a></li>
                        <li>{{news_title}}</li>
                    </ul>
                    <h2>{{news_title}}</h2>

                </div>
                <img src="assets/img/site/illustrations/online_articles.svg" alt="" style="height: 180px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
                <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon cs-hide-small" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10">
            </div>

            <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
        </div>
        <div>
            <app-data-not-found></app-data-not-found>
        </div>

    </div>
</div>