import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

declare var firstTimeAccessChecker;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    programDetails = details;

    constructor() { }

    ngOnInit(): void {
        this.callTo_firstTimeAccessChecker();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    callTo_firstTimeAccessChecker(){
        firstTimeAccessChecker();
    }

}
