<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Register Now</li>
            </ul>
            <h2>Register Now</h2>
        </div>
        <img src="assets/img/site/illustrations/registration.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>


<section class="profile-authentication-area" style="margin-bottom: 100px;">
    <div class="container">

        <div class="row">

            <div class="col-lg-4 col-md-12">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12">
                                    <label class="cu-clr-4 cs-left cs-margin-top"><b>Program Plan</b><sup
                                            class="cs-text-red">*</sup> </label>
                                    <label *ngIf="applied" routerLink='/register-now' class="cs-right cs-large cs-margin cs-btn cs-round"><i
                                            class="bx bx-sync"></i></label>
                                </div>
                            </div>

                            <div id="programPlanSelectorContainer" [class.cs-hide]="applied" class="mt-2 mb-2">
                                <select name='inp_reg_program_plan' class="form-control setDefaultSelect cu-pointer cs-white cs-border" id="programPlanSelector" onchange="changeProgramPlan()">
                                    <option value="" selected disabled>---</option>
                                    <option id="option_{{program.id}}" value="{{program.id}}"
                                        *ngFor="let program of programDetails">
                                        {{program.name}}</option>
                                </select>
                            </div>
                            <div id="programDetails_string" class="cs-hide">
                                {{str_programDetails}}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row" id="reg_not_selected_div">
                    <div class="col-12 cs-display-container" style="height: 300px;">
                        <img src="assets/img/site/illustrations/selection.svg" alt="" class="cs-display-middle" style="height: 200px;">
                        <p class="cs-display-bottommiddle cu-clr-main">Select a Program Plan</p>
                        <br>
                    </div>
                </div>
                <div class="single-courses-box cs-hide" id="reg_selected_plan_div">
                    <iframe src="" id="reg_selected_plan_iframe" width="100%" height="100%" frameborder="0" style="background-color: transparent !important;"></iframe>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="login-form">
                    <div class="cs-bar" id="reg_nav-bar">
                        <div class="cs-bar-item  cs-margin-right cs-round-large cs-right cu-pointer cs-border cs-hover-border-gray">
                        </div>
                        <div class="cs-bar-item  cs-margin-right cs-round-large cs-right cu-pointer cs-border cs-hover-border-gray">
                        </div>
                        <div class="cs-bar-item  cs-margin-right cs-round-large cs-right cu-pointer cs-border cs-hover-border-gray">
                        </div>
                        <div class="cs-bar-item  cs-margin-right cs-round-large cs-right cu-pointer cs-border cs-hover-border-gray">
                        </div>
                        <div class="cs-bar-item cs-gray cs-margin-right cs-round-large cs-right cu-pointer cs-border cs-hover-border-gray">
                        </div>
                    </div>

                    <br>
                    <div id="reg_sec_1">
                        <form id="reg_sec_1_form" (ngSubmit)="callTo_submitRegForm(1);">
                            <input name="inp_reg_program_plan" id="inp_reg_program_plan_id" type="number" class="form-control cs-hide">

                            <h4>Biographical Information</h4>
                            <hr class="cu-hr">

                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-2">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <select name="title" class="form-control setDefaultSelect cu-pointer" id="title" required>
                                            <option disabled selected value>---</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input name="fname" id="fname" type="text" pattern="^[a-zA-Z ]*$" title="Contain only Letters !" class="form-control " required placeholder="First Name">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input name="lname" id="lname" type="text" class="form-control" pattern="^[a-zA-Z ]*$" title="Contain only Letters !" required placeholder="Last Name">
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Date of Birth</label>
                                        <input name="dob" id="dob" type="date" class="form-control " max="{{today}}" required>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Gender</label>
                                        <select name="gender" class="form-control setDefaultSelect cu-pointer" id="gender" required>
                                            <option selected disabled value="">---</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Marital Status</label>
                                        <select name="m_status" class="form-control setDefaultSelect cu-pointer" id="m_status" required>
                                            <option selected disabled value="">---</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="default-btn cu-btn-1">Next<span></span></button>
                        </form>
                    </div>

                    <div id="reg_sec_2" class="cs-hide">
                        <form id="reg_sec_2_form" (ngSubmit)="callTo_submitRegForm(2);">
                            <h4>Communication Preferences</h4>
                            <hr class="cu-hr">

                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Language of Communication</label>
                                        <select name="lng_of_com" id="lng_of_com" class="form-control setDefaultSelect cu-pointer" required>
                                            <option disabled selected value>---</option>
                                            <option value="English">English</option>
                                            <option value="French">French</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Home Language <span class="cs-margin-left form_span"></span></label>
                                        <input name="home_lng" id="home_lng" list="Languages" placeholder="Home Language" class="form-control " required autocomplete="off" onkeyup="checkOptionListForReg(this ,'Home Language');btn_disability(2)" onchange="checkOptionListForReg(this ,'Home Language')">
                                        <datalist id="Languages">
                                            <option disabled selected value>---</option>
                                            <option value="{{language}}" *ngFor="let language of languageList">
                                                {{language}}</option>
                                        </datalist>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="float: right;">
                                <button type="button" class="default-btn" style="width: fit-content;margin-right: 20px;" onclick="navDiv('reg_sec', 1, 6);set_regNavbar(1, 5);">Back<span></span></button>
                                <button type="submit" class="default-btn submit-btn" style="width: fit-content;">Next<span></span></button>
                            </div>
                        </form>

                    </div>

                    <div id="reg_sec_3" class="cs-hide">
                        <form id="reg_sec_3_form" (ngSubmit)="callTo_submitRegForm(3);">
                            <h4>Contact Information</h4>
                            <hr class="cu-hr">
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Email Address<span class="cs-margin-left form_span"
                                                id="email_span"></span></label>
                                        <input name="email" id='email' type="email" class="form-control " placeholder="Email Address" required autocomplete="off" onkeyup="checkEmailAddressForReg(this)">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Country Code <span class="cs-margin-left form_span"></span></label>
                                        <input name="c_code" id="c_code" list="country-code" required placeholder="Country Code" class="form-control " autocomplete="off" onkeyup="checkOptionListForReg(this ,'Country Code');btn_disability(3);" onchange="checkOptionListForReg(this ,'Country Code');btn_disability(3);">
                                        <datalist id="country-code">
                                            <option value="">
                                            <option value="{{countryCode.country}} {{countryCode.code}}"
                                                *ngFor="let countryCode of countryCodeList"></option>
                                        </datalist>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-8">
                                    <div class="form-group">
                                        <label>Contact Number <span class="cs-margin-left form_span"></span></label>
                                        <input name="p_number" id="p_number" type="text" placeholder="Contact Number" pattern="[0-9]+" minlength="5" maxlength="20" title="Contact Number Should Only Contain numbers !" required class="form-control " autocomplete="off" aria-readonly="true">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <textarea name="address" id="address" rows="5" required class="form-control " autocomplete="off" style="width: 100%;"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="float: right;">
                                <button type="button" class="default-btn" style="width: fit-content;margin-right: 20px;" onclick="navDiv('reg_sec', 2, 6);set_regNavbar(2, 5);">Back<span></span></button>
                                <button type="submit" class="default-btn submit-btn" style="width: fit-content;">Next<span></span></button>
                            </div>
                        </form>
                    </div>

                    <div id="reg_sec_4" class="cs-hide">
                        <form id="reg_sec_4_form" (ngSubmit)="callTo_submitRegForm(4);">
                            <h4>Request Additional Information</h4>
                            <hr class="cu-hr">

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Select Program</label>

                                        <div class="col-lg-12 col-md-12 col-sm-12 remember-me-wrap">
                                            <p style="float: right;">
                                                <input type="checkbox" id="checkAll">
                                                <label for="checkAll">All</label>
                                            </p>
                                            <div id="reqAddInfoCheck">
                                                <p *ngFor="let program of programDetails">
                                                    <input type="checkbox" id="check-{{program.id}}" onclick="reqAddInfoCheckFunction()">
                                                    <label for="check-{{program.id}}">{{program.name}}<span
                                                            class="cs-hide">{{program.id}}</span></label>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row" style="float: right;">
                                <button type="button" class="default-btn" style="width: fit-content;margin-right: 20px;" onclick="navDiv('reg_sec', 3, 6);set_regNavbar(3, 5);">Back<span></span></button>
                                <button type="submit" class="default-btn" style="width: fit-content;" onclick="">Confirm<span></span></button>
                            </div>
                        </form>
                    </div>

                    <div id="reg_sec_5" class="cs-hide">
                        <form id="reg_sec_5_form" (ngSubmit)="callTo_submitRegForm(5);">
                            <h4>Your Information</h4>
                            <hr class="cu-hr">
                            <br>
                            <div class="row">
                                <div class="col">
                                    <label>Name</label>
                                </div>
                                <div class="col">
                                    <span id="reg_title">---</span>&nbsp;<span id="reg_fname">---</span>&nbsp;<span id="reg_lname">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Date Of Birth</label>
                                </div>
                                <div class="col">
                                    <span id="reg_dob">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Gender</label>
                                </div>
                                <div class="col">
                                    <span id="reg_gender">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Marital Status</label>
                                </div>
                                <div class="col">
                                    <span id="reg_m_status">---</span>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col">
                                    <label>Language of Communication</label>
                                </div>
                                <div class="col">
                                    <span id="reg_lng_of_com">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Home Language</label>
                                </div>
                                <div class="col">
                                    <span id="reg_home_lng">---</span>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col">
                                    <label>Email Address</label>
                                </div>
                                <div class="col">
                                    <span id="reg_email">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Contact Number</label>
                                </div>
                                <div class="col">
                                    <span id="reg_c_code">---</span>&nbsp;<span id="reg_p_number">---</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Address</label>
                                </div>
                                <div class="col">
                                    <span id="reg_address">---</span>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col">
                                    <label>Requested Additional Information On</label>
                                </div>
                                <div class="col">
                                    <ul id="reg_add_info_view">
                                    </ul>
                                    <textarea name="add_info" class="cs-hide" id="reg_add_info_id" cols="30" rows="10"></textarea>
                                </div>
                            </div>

                            <div class="row" style="float: right;">
                                <button type="button" class="default-btn" style="width: fit-content;margin-right: 20px;" onclick="navDiv('reg_sec', 4, 6);set_regNavbar(4, 5);disableSelector(-1)">Back<span></span></button>
                                <button type="submit" class="default-btn" style="width: fit-content;" id="postRegistration" name="post_registration" onclick="postRegistrationFun()">Register<span></span></button>
                            </div>
                        </form>
                    </div>

                    <div id="reg_sec_6" class="cs-hide">
                        <form id="reg_sec_6_form">
                            <div class="row cu-center">
                                <img src="assets/img/site/illustrations/done.svg" alt="" style="height: 190px;width: auto;">
                                <br>
                                <p>Successfully Registered</p>
                            </div>

                            <div class="row" style="float: right;">
                                <button type="button" class="default-btn" style="width: fit-content;" onclick="location='/register-now'" style="background-color: mediumseagreen;color:whitesmoke;">Done
                                    <span></span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
