<div id="imgViewModel" class="cs-modal" onclick="closeCSModel('imgViewModel')" style="z-index: 999999999999999999999;">
    <span style="position: absolute; top:50px;right:50px;" class="cu-pointer" onclick="closeCSModel('imgViewModel')">
        <span class="cs-text-white cs-xxxlarge  cs-hover-text-amber bx bx-x"></span>
    </span>
    <div class="cs-modal-content cs-animate-zoom cs-transparent" align="center">
        <img id="imgViewModel_img" style="max-height: 500px;width:auto;">
    </div>
</div>

<footer class="footer-area cu-bg-clr-1 footer-bg" style="padding-top: 30px;padding-bottom: 0px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget align-content-sm-center">

                    <div class="align-items-center" align="center">
                        <a routerLink="/" class="logo"><img src="assets/img/site/logo/logo_white_border_2.png"
                                alt="DBIMA logo with golden border" style="height: 100px;width: auto;"></a>
                    </div>
                    <div class="text-center">
                        <h5 class="cu-clr-main">A place to catch your dreams!</h5>
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/dbimaeu/" class="d-block" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://twitter.com/DBIMA_EU" class="d-block" target="_blank"><i
                                        class='bx bxl-twitter'></i></a></li>
                            <li><a href="https://www.instagram.com/dbima_france/" class="d-block" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.linkedin.com/company/dbimaeu/" class="d-block" target="_blank"><i
                                        class='bx bxl-linkedin'></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UC9rc0LuQA7FYBEOHivM1dIA" class="d-block"
                                    target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget detail-widget pl-5">
                    <h3 class="cu-clr-main">Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/download-resources">Download Resources </a></li>
                        <li><a routerLink="/news-and-events">News & Event</a></li>
                        <li><a routerLink="/professors">Professors</a></li>
                        <li><a routerLink="/gallery">Gallery</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget detail-widget">
                    <h3 class="cu-clr-main">Our programs</h3>
                    <ul class="footer-links-list">
                        <li *ngFor="let program of programDetails"><a routerLink="/{{program.url}}">{{program.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget detail-widget">
                    <h3 class="cu-clr-main">Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>75 rue Alexandre Dumas, <br>75020 PARIS,FRANCE.</li>
                        <li class="cs-hide"><i class='bx bx-phone-call'></i><a href="tel:+123456789">+123456789</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@dbima.eu">info@dbima.eu</a></li>
                    </ul>
                    <div class="cs-row" style="padding-top: 5px;">
                        <img src="assets/img/site/footer/SDB.png" class="" alt="CIDB"
                            style="height: 100px;width: auto;">
                        <img src="assets/img/site/footer/DBIEM.png" class="" alt="DBIEM"
                            style="height: 100px;width: auto;">
                    </div>
                </div>
            </div>

        </div>

        <div class="footer-bottom-area" style="margin-top: 0px;">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i><a target="_blank" href="https://www.dbima.eu"
                            class="cu-hover-clr-secondary">Don Bosco International Media Academy</a> <br> Designed &
                        Developed by <a target="_blank" href="https://www.esacinetech.com"
                            class="cu-hover-clr-secondary">ESA
                            CineTech</a> and <a target="_blank" href="https://www.cagliero.eu/"
                            class="cu-hover-clr-secondary">Cagliero
                            Creative Solutions</a> <br> All rights reserved.
                    </p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/cookie-and-privacy-policy">Cookie & Privacy Policy</a></li>
                        <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</footer>

<!-- <div class="go-top"><i class='bx bx-chevron-up'></i></div> -->

<ngx-scrolltop></ngx-scrolltop>