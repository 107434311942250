import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-eabhes',
  templateUrl: './home-eabhes.component.html',
  styleUrls: ['./home-eabhes.component.scss']
})
export class HomeEabhesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
