<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/programs">Our Programs</a>
                </li>
                <li>{{program_name}}</li>
            </ul>
            <h2 class="cs-display-middle">{{program_name}}</h2>
        </div>
        <img src="assets/img/site/illustrations/art_museum.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>



<section class="courses-details-area">

    <div class="container mb-3">
        <div class="row">
            <div class="col-lg-8 col-md-12">

                <div class="products-details-tabs">
                    <ngx-tabset>

                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <div class="courses-details-image-style-two text-center">
                                    <img src="assets/img/site/programs/p2/p.jpg" alt="{{programDetails.p_alt}}">
                                </div>
                                <div class="courses-details-desc-style-two">
                                    <h3>Description:</h3>
                                    <p>The main goal of our bachelor program is to create a new generation of professionals with advanced theoretical and most importantly, practical skills. We developed our program for a direct insertion in professional cinema and the audiovisual world. </p>
                                    <p>Shifting between theoretical and practical courses for 3 years, our students are trained by professionals that are selected for their pedagogical approach and the transmission of their knowledge. </p>
                                    <p>Learning on equipment that is used in every professional environment and guided by teachers who are professionals in their activity, DBIMA students will be prepared for work as soon as they finish their bachelor program.</p>
                                    <p>As an international school of cinema, we focus on transmitted knowledge that is applicable on every overseas shoot, and with a special attention on the European aesthetic and technics, as well as the transmission of the French cinema touch.</p>

                                    <h3>Here are noticeable points of our Bachelor program:</h3>
                                    <ul class="description-features-list ">
                                        <li><strong>The first year is a dedicated to the transmission of the basics in all themes (history of cinema, camera/lighting, sound, production, script- writing, management...) </strong></li>
                                        <li><strong>2nd year is dedicated to a hands-on approach of the work. </strong></li>
                                        <li><strong>Masterclasses are organized regularly for students to meet advanced professionals and discover their careers, their choices, experience, as well as their actual work. </strong></li>
                                        <li><strong>Practical classes are held twice a week at a minimum, from camera training, lighting courses, to sound-on-set courses as well as editing, colour grading and sound mixing courses. </strong></li>
                                        <li><strong>Each year, the last three months are dedicated to shooting the students short films. </strong></li>
                                        <li><strong>During their studies students are asked to complete and present several projects or finished works. </strong></li>
                                        <li><strong>Grading considers presence, involvement, practice, and theory. </strong></li>
                                        <li><strong>By the end of a student’s studies, they will have a personal show-reel to represent their work. </strong></li>
                                    </ul>
                                    <br>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Curriculum">

                            <div class="">

                                <div class="container courses-curriculum-contain" style="margin-left: 0rem;">
                                    <div class="faq-accordion">
                                        <accordion>
                                            <accordion-group heading="Year 1">
                                                <ul>
                                                    <li><strong>Cinematography Basics I & II </strong></li>
                                                    <li><strong>Directing Basics I & II </strong></li>
                                                    <li><strong>Editing Basics I & II </strong></li>
                                                    <li><strong>Scriptwriting Basics I & II </strong></li>
                                                    <li><strong>Principles of Documentary Making </strong></li>
                                                    <li><strong>History of Cinema </strong></li>
                                                    <li><strong>Production Management Basics I & II </strong></li>
                                                    <li><strong>Film Analysis </strong></li>
                                                    <li><strong>Sound for Post Production Basics I & II </strong></li>
                                                    <li><strong>English Language </strong></li>
                                                    <li><strong>Production I </strong></li>
                                                    <li><strong>Final Project I </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                    <li><strong>Sound on Set Basics </strong></li>
                                                    <li><strong>Humanitarian Mission / Volunteer Work </strong></li>
                                                </ul>
                                            </accordion-group>
                                            <accordion-group heading="Year 2">
                                                <ul>
                                                    <li><strong>Directing Intermediate III & IV </strong></li>
                                                    <li><strong>Cinematography Intermediate III & IV </strong></li>
                                                    <li><strong>Production II </strong></li>
                                                    <li><strong>Scriptwriting Intermediate III & IV </strong></li>
                                                    <li><strong>Editing Intermediate III & IV </strong></li>
                                                    <li><strong>Marketing & Pitching </strong></li>
                                                    <li><strong>Sound for Post Production Intermediate III & IV </strong></li>
                                                    <li><strong>Production Management Intermediate III </strong></li>
                                                    <li><strong>French Language </strong></li>
                                                    <li><strong>English Language </strong></li>
                                                    <li><strong>Final Project II </strong></li>
                                                    <li><strong>Internship (Production Period) </strong></li>
                                                    <li><strong>Acting Direction I </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                    <li><strong>Humanitarian Mission / Volunteer Work </strong></li>
                                                </ul>
                                            </accordion-group>
                                            <accordion-group heading="Year 3">
                                                <ul>
                                                    <li><strong>Advanced Production Management </strong></li>
                                                    <li><strong>Advanced Cinematography </strong></li>
                                                    <li><strong>Advanced Directing </strong></li>
                                                    <li><strong>Advanced Editing </strong></li>
                                                    <li><strong>Advanced Scriptwriting </strong></li>
                                                    <li><strong>Advanced Sound for Post Production </strong></li>
                                                    <li><strong>Internship (Production period) </strong></li>
                                                    <li><strong>Final Project III </strong></li>
                                                    <li><strong>Acting Direction II </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                    <li><strong>Humanitarian Mission / Volunteer Work </strong></li>
                                                </ul>
                                            </accordion-group>
                                        </accordion>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Requirements">
                            <div class="courses-requirements">

                                <div class="courses-details-desc-style-two">

                                    <h3>1st Year Admission of Bachelor of Arts in Cinema and Audiovisual</h3>
                                    <ul class="description-features-list ">
                                        <li>Baccalaureate or Equivalent (High School Diploma, 12th Grade Degree, AISSCE, A Levels, BTEC National Diploma …) (Copy of Diploma must be provided). </li>
                                        <li>Or Copy of Academic reports for the 1st and 2nd Semester (If studying in High School or equivalent). </li>
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees once selected. </li>
                                    </ul>

                                    <h3>Direct entrance in 2nd Year of Bachelor of Arts in Cinema and Audiovisual</h3>
                                    <ul class="description-features-list ">
                                        <li>Baccalaureate or Equivalent (High School Diploma, 12th Grade Degree, AISSCE, A Level…) (Copy of Diploma must be provided). </li>
                                        <li>Equivalence in Credits (60 Credits ECTS) of one year of studies in Cinema, Audiovisual, Mass Media and Communication or Arts school (Academic reports for the 1st year of study must be provided). </li>
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees once selected. </li>
                                    </ul>

                                    <h3>Direct entrance in 3rd Year of Bachelor of Arts in Cinema and Audiovisual, Majoring in Directing, Cinematography, Production or Post-Production</h3>
                                    <ul class="description-features-list ">
                                        <li>Baccalaureate or Equivalent (High School Diploma, 12th Grade Degree, AISSCE, A Level…) (Copy of Diploma must be provided). </li>
                                        <li>Equivalence in Credits (120 Credits ECTS) of two years of studies in Cinema, Audiovisual, Mass Media and Communication or Arts school (Academic reports for the 1st & 2nd year of study must be provided). </li>
                                        <li>BTS Diploma or Equivalent (BTEC Higher Education Diploma, Diploma of Higher Education…) (Copy of Diploma must be provided). </li>
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees once selected. </li>

                                    </ul>

                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 " style="padding-top: 100px;">
                <div class="courses-sidebar-information">
                    <ul class="info ">
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-time "></i> Duration</span> 3 years
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-distance-learning "></i> Subjects</span> 38
                            </div>
                        </li> -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-brain-process"></i> Credits</span> 180 ECTS
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-html "></i> Language</span> English
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-certification "></i> Certificate</span> European Degree
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box ">
                        <a routerLink="/register-for/{{program_id}}/{{program_url}}" class="default-btn ">Apply Now<i
                                class="flaticon-verify ml-2"></i><span></span></a>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pt-100 pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>More Courses You Might Like</h2>
        </div>

        <div class="row ">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program1-mini></app-program1-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program3-mini></app-program3-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program4-mini></app-program4-mini>
                </div>
            </div>
        </div>
    </div>
</section>