import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { getAllFlyers } from 'src/app/models/endpoints';
import { Router } from '@angular/router';
@Component({
    selector: 'app-download-resources',
    templateUrl: './download-resources.component.html',
    styleUrls: ['./download-resources.component.scss']
})
export class DownloadResourcesComponent implements OnInit {
    flyerDetails: any;
    baseFileUrl = "https://admin.dbima.eu/app/assets/img/uploads/ps-flyers/";
    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    ngOnInit(): void {
        this.callTo_flyers();
    }

    callTo_flyers() {
        let flyerObj = [];
        let DefaultImgUrl = "assets/img/defaultImage.jpg";
        let thumbImgUrl = "https://admin.dbima.eu/app/assets/img/uploads/ps-flyers/thumbnails/";

        this.http.get<any>(environment.baseUrl + getAllFlyers).subscribe({
            next: (data) => {
                if (data.status == 1) {

                    flyerObj = data.data;

                    if (flyerObj.length != 0) {
                        this.noData = false;
                    }

                    const flyerData = flyerObj.map(fileType);

                    function fileType(x) {
                        let fileExt = x.file_name.split('.').pop();
                        const flyerThumb = thumbImgUrl + x.thumbnail;

                        if (fileExt == "pdf" || fileExt == "PDF") {
                            x.type = "Document";

                            if (x.thumbnail == null || x.thumbnail == "") {
                                x.thumbnail = DefaultImgUrl;
                            }
                            else {
                                x.thumbnail = flyerThumb;
                            }
                        } else {
                            x.type = "Image";
                            if (x.thumbnail == null || x.thumbnail == "") {
                                x.thumbnail = DefaultImgUrl;
                            } else {
                                x.thumbnail = flyerThumb;
                            }
                        }

                        return x;
                    }

                    this.flyerDetails = flyerData;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            },
            error: (error) => {
                // this.openSnackBar('There was an error! ,' + error.message, 0);
                console.log(error.message);
            },
        });
    }




}
