<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>History</li>
            </ul>
            <h2>History</h2>

        </div>
        <img src="assets/img/site/illustrations/in_thought.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="terms-of-service-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">

                    <div class="row">
                        <div class="col-lg-4 col-md-12"
                            style="display: grid;padding: 0px;margin: 0px;place-items: start center;">
                            <img src="assets/img/site/history/don_bosco _butterflies.png"
                                alt="St. John Bosco vector art with butterflies " style="height: 400px;width: auto;">
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <P><b>Our Origin</b></P>
                            <p><b>DBIMA’s</b> vision traces back to the original Ménilmontant Theatre built by Don Bosco in 1883. 
                                Today, we aspire to create a modern hub for arts and media education, blending tradition with technical advancement. 
                                Become part of a pioneering initiative that echoes the spirit of our founder.
                            </p>
                            <p><b>Every dream has a story…</b></p>
                            <p>
                                <b>Don Bosco International Media Academy (DBIMA)</b> is the first Salesian Cinema School 
                                in Europe to promote media education in 21st century. DBIMA aims to be an ingenious 
                                center for All Youth through digital media training. The mission statement of<b> DBIMA </b>
                                is opening doors of opportunities through media education for youth to inspire the digital and media world. 
                            </p>
                            <p><b>How? When? Why? Who?</b></p>
                            <p>Past 3 Salesian General Chapters (2006-2020) enunciated a lot about media, digital world, and communication.
                                The Salesian Congregation encouraged the Salesians around the world to get trained and 
                                engaged actively in the media world. In response to this call, the Salesians of the 
                                Province of FRB under the leadership of Daniel FEDERSPIEL decided to send one of his 
                                confreres, John Paul SWAMINATHAN, a Salesian hailing from Tamil Nadu-India, to do his 
                                master studies in filmmaking to Paris from 2015 to 2017. During his studies, he discovers 
                                an important social truth that most of film-students were sons and daughters of filmmakers, 
                                and they were undoubtedly very rich. This reality raised a lot of questions: If someone is 
                                poor, is he or she not allowed to dream to be a filmmaker? Is he or she not allowed to get 
                                qualified in the media? The Salesians run more than 1,800 schools in Europe but not even 
                                one school offers media education and why? With these questions, John Paul met the provincial
                                , Daniel FEDERSPIEL in 2016. In dialogue with him, John Paul discovers something he never 
                                heard before: 
                            </p>
                            <p>When Don Bosco came to Paris in 1883, the city was still recovering from a cholera epidemic and the 
                                political upheaval in the wake of ‘La Semaine Sanglot’, the final bloody end of the Paris 
                                commune. To establish the Salesian presence in the city, he chose the tough hilltop 
                                neighborhood of Ménilmontant, a depressed, densely populated working-class area inhabited by 
                                street youths that was the last stronghold of the fervently anti-religious communards 
                                (Ceria, Volume XVIII 1886-1888). After much struggle, he built a home, an oratory, and a 
                                school for boys. A year later when the opportunity came to expand the Salesian presence, 
                                he built the Theatre Ménilmontant as a place of refuge for children to learn and participate 
                                in the arts.</p>
                            <p>The project of DBIMA will mirror the original Ménilmontant Theatre in the coming years and the 
                                surrounding Salesian property will be revitalized and developed into a bustling arts and 
                                media hub that mixes media education and commercial enterprise in one technically advanced 
                                campus. This new ‘blended’ initiative will be the first of its kind with students from all 
                                over the world, benefiting from a Salesian spirited arts and media education with the 
                                potential of post-graduation career opportunities. The real hero and founder of DBIMA is 
                                Don Bosco himself.  </p>
                            <p><b>Education </b>is the very heart of the Salesian charism. Accompanying young people to 
                                adopt to these digital media tools in a constructive way is to make them a real form of 
                                expression in an artistic way. This mission at the heart of DBIMA is to create a cinema 
                                and an audiovisual school that offers young people training in media to which they have not 
                                had access until now, whether for economic, social, or psychological reasons. This 
                                initiative is based on the idea that media training is indispensable to all. </p>
                            <p>Re-started the original vision of Don Bosco in the form of DBIMA in 2020 during the Covid-19 
                                crisis with 7 students, it turned to be 24 in 2021, and we are set to grow more and more 
                                in the coming years. DBIMA stood firm in all the difficulties and progressed always 
                                forward with a strong conviction and a clear goal to serve all. </p>
                            <p> For more information, please contact us: <a href="mailto:info@dbima.eu"
                                class="cu-clr-3">info@dbima.eu</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>