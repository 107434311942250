<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <div class="cs-row cs-hide-small cs-hide-medium">
                    <div class="cs-col me-lg-1" style="width: 70px;">
                        <img src="assets/img/site/logo/logo_white_border.png" alt="DBIMA logo" style="height: 70px;">
                    </div>
                    <div class="cs-rest p-1 mt-2">
                        <span class="logo-text">Don Bosco International Media Academy</span>
                        <br>
                        <b class="logo-sub-text">Établissement d'Enseignement Supérieur Privé</b>
                    </div>
                </div>

                <div class="logo cs-hide-large">
                    <a routerLink="/" class="cs-wide" style=" font-size: 20px;"><img
                            src="assets/img/site/logo/logo_white_border.png" alt="DBIMA logo"
                            style="height: 30px;margin-right: 10px;"><b class="logo-text">DBIMA</b></a>
                </div>
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">

                    <li class="nav-item">
                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Home</a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/news-and-events" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">News & Events</a>
                    </li>

                    <li class="nav-item">

                        <a href="javascript:void(0)" routerLink="/programs" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Our Programs <i
                                class='bx bx-chevron-down'></i></a>

                        <ul class="dropdown-menu">

                            <li class="nav-item" *ngFor="let program of programDetails"><a routerLink="/{{program.url}}"
                                    class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{program.name}}</a></li>
                            <li>
                                <hr>
                            </li>
                            <li class="nav-item"><a routerLink="/download-resources" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Download
                                    Resources <i class='bx bx-cloud-download'></i></a>
                            </li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                        </ul>
                    </li>

                    <li class="nav-item">

                        <a href="javascript:void(0)" class="nav-link">Our Faculty <i class='bx bx-chevron-down'></i></a>

                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">About us</a>
                            </li>
                            <li class="nav-item"><a routerLink="/professors" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Team DBIMA</a>
                            </li>
                            <li class="nav-item"><a routerLink="/aims-and-ethos" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Vision &amp; Mission
                                </a>
                            </li>
                            <li class="nav-item"><a routerLink="/history" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">History</a></li>
                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                            <li class="nav-item"><a routerLink="/partners" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Partners</a></li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" routerLink="/" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" routerLink="/news-and-events"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            (click)="toggleClass()">News & Events</a>
                    </li>
                    <li class="nav-item">

                        <a href="javascript:void(0)" onclick="toggleNavBar('program')" class="nav-link">Our Programs <i
                                class='bx bx-chevron-down'></i>
                        </a>
                        <ul class="dropdown-menu cs-hide" id="programDropdown">
                            <li class="nav-item" *ngFor="let program of programDetails">
                                <a routerLink="/{{program.url}}" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{program.name}}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/download-resources" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    Download Resources
                                    <i class='bx bx-cloud-download' style="transform: none;"></i>
                                </a>
                            </li>

                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">FAQ's</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" onclick="toggleNavBar('faculty')" class="nav-link">Our Faculty <i
                                class='bx bx-chevron-down'></i>
                        </a>
                        <ul class="dropdown-menu cs-hide" id="facultyDropdown">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">About us</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/professors" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Professors</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/aims-and-ethos" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    Vision &amp; Mission
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/history" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">History</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Gallery</a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/partners" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Partners</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" routerLink="/contact-us" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact Us</a>

                    </li>
                    <li align="center" style="padding-bottom: 25px;padding-top: 25px;">
                        <hr class="hr-dash">
                        <a class="default-btn" routerLink='register-now' (click)="toggleClass()">
                            <i class="flaticon-verify"></i> Register
                            <span></span>
                        </a>
                        <a href="academic-portal" (click)="toggleClass()" target="_blank" class="default-btn ms-lg-2"
                            style="padding:20px;background-color: #b3833c;margin-left: 20px;">
                            <i class="fas fa-chalkboard-teacher" style="left: 12px;top: 13px;"></i>
                            <span></span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="others-option d-flex align-items-center">
                <div class=" option-item cs-hide-small cs-hide-medium">
                    <a class="default-btn" routerLink='register-now'>
                        <i class="flaticon-verify"></i> Register
                        <span></span>
                    </a>
                    <a href="academic-portal" target="_blank" class="default-btn ms-lg-2"
                        style="padding:23px;background-color: #b3833c;">
                        <i class="fas fa-chalkboard-teacher" style="left: 12px;top: 15px;"></i>
                        <span></span>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</div>

<!-- The Modal -->
<div id="firstTimeAccessChecker_modal" class="cs-modal cookie-model" style="z-index: 9999999999999999999;">
    <div class="cs-modal-content cs-animate-top cs-card-4 cs-round">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <div class="cs-row cs-hide-small cs-hide-medium">
                        <div class="cs-col me-lg-1" style="width: 50px;">
                            <img src="assets/img/site/logo/logo_white_border.png" alt="DBIMA logo"
                                style="height: 50px;">
                        </div>
                        <div class="cs-rest p-1">
                            <span class="logo-text cs-medium">Don Bosco International Media Academy</span>
                            <p class="logo-sub-text cs-tiny">Établissement d'Enseignement Supérieur Privé</p>
                        </div>
                    </div>
                    <div class="logo cs-hide-large">
                        <a routerLink="/" class="cs-wide" style=" font-size: 20px;"><img
                                src="assets/img/site/logo/logo_white_border.png" alt="DBIMA logo"
                                style="height: 30px;margin-right: 10px;"><b class="logo-text">DBIMA</b></a>
                    </div>
                </h5>
                <!-- <button type="button" class="close cs-btn cs-large cs-round cs-border" onclick="closeModal('firstTimeAccessChecker_modal')">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <h5><b>Cookie & Privacy Policy</b></h5>

                <p>DBIMA use cookies or similar technologies and process personal data that may be cross-referenced with
                    information provided as part of our services for the following purposes: To improve your user
                    experience, To produce audience statistics, To measure the performance of these advertisements, To
                    use precise geolocation data, To offer you functionalities relating to social networks, to fight
                    against fraud. Some cookies are necessary for the operation of the site and our services. You can
                    accept or continue browsing without accepting.<br>

                    <a class="policyTitles" href="privacy-policy">Cookie & Privacy Policy</a> and <a
                        class="policyTitles" href="terms-and-conditions">Terms &
                        Conditions</a>
                </p>
                <hr>
                <div>
                    <a onclick="removeFTAC_Modal('accept');" class=" mx-0 default-btn cu-pointer m-auto"
                        style="padding-left:30px;">
                        Accept<span></span>
                    </a>
                    <a onclick="removeFTAC_Modal('continue-without-accept');"
                        class=" mx-3 default-btn ms-lg-2 cu-pointer"
                        style="background-color: #b3833c;  padding-left:30px;">
                        Continue Without Accepting
                        <span></span>
                    </a>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" onclick="closeModal('firstTimeAccessChecker_modal')">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>