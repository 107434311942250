import { Component, OnInit } from '@angular/core';
declare var postContactMessageFun;

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        document.getElementById('contactForm').removeAttribute("novalidate");
    }

    callToPostContactMessageFun() {
        postContactMessageFun();
    }
}
