<div class="single-courses-box m0 mb-0" *ngIf="mini_program_state[0]">
    <app-program1-mini></app-program1-mini>
</div>
<div class="single-courses-box m0 mb-0" *ngIf="mini_program_state[1]">
    <app-program2-mini></app-program2-mini>
</div>
<div class="single-courses-box m0 mb-0" *ngIf="mini_program_state[2]">
    <app-program3-mini></app-program3-mini>
</div>
<div class="single-courses-box m0 mb-0" *ngIf="mini_program_state[3]">
    <app-program4-mini></app-program4-mini>
</div>
