import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    programDetails = details;

    constructor() { }

    ngOnInit(): void {
    }

}
