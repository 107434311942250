<div [ngStyle]="{'position': load !=0 && bannerImgdata.length > 0 ? 'absolute':''}" class="banner-container">
    <div class="banner-wrapper"  *ngFor="let Image of bgImage;" [ngStyle]="{'background-image' : load == 0 || bannerImgdata.length == 0? 'url('+Image.img+')' : ''}">
        <div class="d-table" style="background-color: #00000033;">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-wrapper-text cs-text-white p-3">
                        <div class="cs-hide-large cs-hide-medium">
                            <h1>Welcome to DBIMA</h1>
                            <p class="cs-large">Your gateway to a vibrant education in cinema (Filmmaking) and audiovisual arts!</p>
                        </div>
                        <div class="cs-hide-small">
                            <h1 style="margin-top: 50px;font-size: 60px;">Welcome to DBIMA</h1>
                            <p class="cs-xlarge">Your gateway to a vibrant education in cinema (Filmmaking) and audiovisual arts!</p>
                        </div>
                        <br>
                        <a routerLink="/register-now" class="default-btn"><i
                                class="flaticon-user"></i>REGISTER<span></span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape11"><img src="assets/img/banner-shape12.png" alt="Doted grid"></div>
        <div class="banner-shape99"><img src="assets/img/site/shapes/banner_icon.png" alt="white lense icon"></div>
        <div class="banner-shape13"><img src="assets/img/site/shapes/lens.png" alt="pink lense icon" style="height: 200px;width: auto;"></div>
    </div>
</div>

<div *ngIf="bannerImgdata.length > 0" >
    <owl-carousel-o [options]="slides">
        <ng-template *ngFor="let imgData of bannerImgdata;" carouselSlide>
            <div  class="banner-wrapper" style="background-image: url({{baseImgUrl+imgData.imageUrl}});" >
               <!-- The below text won't appear jsut for image alignment  -->
                <div class="d-table" style="visibility: hidden;">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="banner-wrapper-text cs-text-white">
                                <div class="cs-hide-large cs-hide-medium">
                                    <h1>Welcome to DBIMA</h1>
                                    <p class="cs-large">Your gateway to a vibrant education in cinema (Filmmaking) and audiovisual arts!</p>
                                </div>
                                <div class="cs-hide-small">
                                    <h1 style="margin-top: 50px;font-size: 70px;">Welcome to DBIMA</h1>
                                    <p class="cs-xxlarge">Your gateway to a vibrant education in cinema (Filmmaking) and audiovisual arts!</p>
                                </div>
                                <br>
                                <a routerLink="/register-now" class="default-btn"><i
                                        class="flaticon-user"></i>REGISTER<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>