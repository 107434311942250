<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/programs">Our Programs</a>
                </li>
                <li>{{program_name}}</li>
            </ul>
            <h2 class="cs-display-middle">{{program_name}}</h2>
        </div>
        <img src="assets/img/site/illustrations/book_lover.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon"
            style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>



<section class="courses-details-area">

    <div class="container mb-3">
        <div class="row">
            <div class="col-lg-8 col-md-12">

                <div class="products-details-tabs">
                    <ngx-tabset>

                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <div class="courses-details-image-style-two text-center">
                                    <img src="assets/img/site/programs/p4/p.jpg" alt="{{programDetails.p_alt}}">
                                </div>
                                <div class="courses-details-desc-style-two">
                                    <h3>Eligibility:</h3>
                                    <p>For media students or film professionals who already have some prior studies and/or work experience but who require a diploma, this course offers a “fast-track” way to receive a degree in one year. </p>
                                    <h3>Curriculum:</h3>
                                    <p>Advanced course in visual media, including concept creation, story development, scriptwriting, pre-production, production (filming) and post-production (editing, VFX and audio). Detailed syllabus will be communicated on the orientation day. </p>
                                    <h3>Certification:</h3>
                                    <p>Fastrack - FILM & TELEVISION Diploma to all students who successfully complete the one-year program, awarded by Don Bosco International Media Academy and Campus International Don Bosco. </p>
                                    <br>

                                    <h5>Scope:</h5>
                                    <ul class="description-features-list ">
                                        <li><strong>An intense and thorough expediated course which provides a solid platform to explore new dimensions in the field of International Media and Entertainment. </strong></li>
                                        <li><strong>This course prepares students for the burgeoning media field, and to be successful in the professional international world of Visual Media. </strong></li>
                                        <li><strong>This course has 2 semesters. The first semester will be more on mastering the basic language in filmmaking. The second semester will be more on production and post-production on practical projects. </strong>
                                        </li>
                                    </ul>
                                    <br>
                                    <!-- <h3>Availability:</h3>
                                    <p>Capacity is 12 students at a time on our Paris campus.</p> -->
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Curriculum">

                            <div class="">
                                <div class="container courses-curriculum-contain" style="margin-left: 0rem;">
                                    <!-- <hr class=" cu-hr"> -->
                                    <div class="faq-accordion">
                                        <accordion>
                                            <accordion-group heading="Year 1">
                                                <ul>
                                                    <li><strong>Cinematography Basics I & II </strong></li>
                                                    <li><strong>Directing Basics I & II</strong></li>
                                                    <li><strong>Editing Basics I & II </strong></li>
                                                    <li><strong>Scriptwriting Basics I & II </strong></li>
                                                    <li><strong>Principles of Documentary Making </strong></li>
                                                    <li><strong>History of Cinema </strong></li>
                                                    <li><strong>Production Management Basics I & II </strong></li>
                                                    <li><strong>Film Analysis </strong></li>
                                                    <li><strong>Sound for Post Production Basics I & II </strong></li>
                                                    <li><strong>English Language </strong></li>
                                                    <li><strong>Production I </strong></li>
                                                    <li><strong>Final Project I </strong></li>
                                                    <li><strong>NGO Involvement </strong></li>
                                                    <li><strong>Sound on Set Basics </strong></li>
                                                    <li><strong>Humanitarian Mission / Volunteer Work </strong></li>
                                                </ul>
                                            </accordion-group>
                                        </accordion>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Requirements">
                            <div class="courses-requirements">

                                <div class="courses-details-desc-style-two">

                                    <!-- <h3>One Year Film Program (Learning the Basics of Film Making)</h3> -->
                                    <ul class="description-features-list ">
                                        <li>Letter of motivation. </li>
                                        <li>Online Interview with Selection Committee. </li>
                                        <li>Presentation of personal work. </li>
                                        <li>Registration fees once selected. </li>
                                    </ul>

                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 " style="padding-top: 100px;">
                <div class="courses-sidebar-information">
                    <ul class="info ">
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-time "></i> Duration</span> 1 year
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-brain-process"></i> Credits</span> 60 ECTS
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-distance-learning "></i> Lessons</span> 20
                            </div>
                        </li> -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-html "></i> Language</span> English
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center ">
                                <span><i class="flaticon-certification "></i> Certificate</span> European Transcript

                            </div>
                        </li>
                    </ul>

                    <div class="btn-box ">
                        <a routerLink="/register-for/{{program_id}}/{{program_url}}" class="default-btn ">Apply Now<i
                                class="flaticon-verify ml-2"></i><span></span></a>
                    </div>



                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pt-100 pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>More Courses You Might Like</h2>
        </div>

        <div class="row ">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program1-mini></app-program1-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program2-mini></app-program2-mini>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <app-program3-mini></app-program3-mini>
                </div>
            </div>
        </div>
    </div>
</section>