<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
            <h2>Contact Us</h2>

        </div>
        <img src="assets/img/site/illustrations/contact_us.svg" alt="" style="height: 190px;width: auto;" class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/site/shapes/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/site/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="contact-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                    <p>Don Bosco International Media Academy is a non-profit school created to offer every student regardless of means the best education in a technically advanced and artistically supportive environment.
                    </p>

                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>75 rue Alexandre Dumas , <br>75020 PARIS , FRANCE.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-mail-send'></i>
                            </div>
                            <h3>Contact</h3>
                            <!--<p>Mobile: <a href="tel:+33622310287">(+336) - 2231 - 0287</a></p>-->
                            <p><a href="mailto:info@dbima.eu">info@dbima.eu</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09h30 - 17h30</p>
                            <p>Sunday & Saturday: Closed</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">

                    <div id="contact_sec_1">
                        <h2>Ready to Get Started?</h2>
                        <p>Your email address will not be published.</p>

                        <form id="contactForm" (ngSubmit)="callToPostContactMessageFun();">
                            <div class="row">
                                <input type="text" name="postContactMessage" id="" value="true" class="cs-hide">

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="cm_name" required placeholder="Your name">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="cm_email" required placeholder="Your email address">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone-number" id="cm_phone_number" pattern="[0-9]+" minlength="5" maxlength="20" title="Contact Number Should Only Contain numbers !" required placeholder="Your phone number with country code">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" id="cm_message" cols="30" rows="5" required minlength="10" placeholder="Write your message..."></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" id="postContactMessageBtn">Send
                                        Message<span></span></button>
                                    <!-- <button type="button" class="default-btn" id="postContactMessageBtn" (click)="callToPostContactMessageFun()">Send
                                        Message<span></span></button> -->
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="contact_sec_2" class="cs-hide">
                        <div class="row cu-center">
                            <img src="assets/img/site/illustrations/message_sent.svg" alt="" style="height: 190px;width: auto;">
                            <div class="cu-center">
                                <br>
                                <h2>Message sent</h2>
                                <p>Thank you for Contacting us</p>
                                <p>We will reply you As soon as Possible</p>
                                <hr>
                            </div>
                            <button type="button" class="default-btn" style="padding-left: 25px;width: fit-content;" onclick="navDiv('contact_sec', 1, 2);">Send
                                New Message<span></span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1312.5843390823727!2d2.3979766!3d48.8549938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6733a687bdd45%3A0x3caab2a86b112ea0!2sDon%20Bosco%20International%20Media%20Academy%20(DBIMA)!5e0!3m2!1sen!2slk!4v1587298274264!5m2!1sen!2slk"></iframe>
</div>
