import { Component, OnInit } from '@angular/core';
import { details } from 'src/app/models/program-details';

@Component({
  selector: 'app-program4',
  templateUrl: './program4.component.html',
  styleUrls: ['./program4.component.scss']
})
export class Program4Component implements OnInit {

    programDetails = details[3];

    program_id = this.programDetails.id;
    program_name = this.programDetails.name;
    program_url = this.programDetails.url;

    constructor() {}

    ngOnInit(): void {}
}
